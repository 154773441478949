import React, { useState, useEffect } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import SiteStyles from '../Site.module.css';
import Styles from './Register.module.css'
import TextStyles from '../TextStyles.module.css'
import { useLocation, useNavigate } from 'react-router-dom';
import ErrorItem from '../App/subcomponents/ErrorItem';
import { Link } from 'react-router-dom';
import Config from '../../config.json'

const Register = (props) => {
    let navigate = useNavigate();
    let location = useLocation();

    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [tosPrivacyAccepted, setTosPrivacyAccepted] = useState(false);

    const [nameErrorMsg, setNameErrorMsg] = useState(null);
    const [emailErrorMsg, setEmailErrorMsg] = useState(null);
    const [passwordErrorMsg, setPasswordErrorMsg] = useState(null);
    const [confirmPasswordErrorMsg, setConfirmPasswordErrorMsg] = useState(null);
    const [tosErrorMsg, setTosErrorMessage] = useState(null);
    const [otherErrorMsg, setOtherErrorMsg] = useState(null);

    const [whereDidYouHearAboutUs, setWhereDidYouHearAboutUs] = useState("");
    const [otherSource, setOtherSource] = useState("");

    // Form validates and returns false if there are errors. 
    const prelimValidation = () => {

        var errors = false;
        setOtherErrorMsg(null);

        // Name
        if (name === null || name.trim() === "" || name.trim().includes(" ") === false) {
            setNameErrorMsg("Enter your full name correctly")
            errors = true;
        } else { setNameErrorMsg(null) }

        // Email
        if (email === null || email.trim() === "" || email.includes("@") === false || email.includes(".") === false) {
            setEmailErrorMsg("Enter your e-mail correctly")
            errors = true;
        } else {
            setEmailErrorMsg(null)
        }

        // Password & Confirmation
        if (password === null || password.trim() === "" || password.length < 8) {
            setPasswordErrorMsg("Enter a password that is at least 8 characters in length")
            errors = true;
        } else {
            setPasswordErrorMsg(null);
            // if (confirmPassword === null || confirmPassword.trim() === "" || confirmPassword !== password) {
            //     setConfirmPasswordErrorMsg("Your passwords are not matching")
            //     errors = true;
            // } else { setConfirmPasswordErrorMsg(null); }
        }

        // Accept TOS
        if (!tosPrivacyAccepted) {
            setTosErrorMessage("Please Accept the Terms & Conditions and Privacy Policy")
            errors = true;
        } else { setTosErrorMessage(null) }

        return !errors;
    }

    useEffect(() => {
        console.log(location.state)
        document.getElementById('top')?.scrollIntoView({ behavior: 'smooth' });
    }, [])

    const submitForm = (e) => {
        e.preventDefault(); // So the page doesn't refresh

        if (prelimValidation()) { // Basic validation

            axios.post(`${Config.API_BASE_URL}/auth/register`, {
                name: name,
                email: email,
                password: password,
                whereDidYouHearAboutUs: whereDidYouHearAboutUs === "Other" ? `Other: ${otherSource}` : whereDidYouHearAboutUs,
                URLRef: props.URLRef,
                selectedCourse: (location.state && location.state.selectedCourse) ? location.state.selectedCourse : null,
                selectedTag: (location.state && location.state.selectedTag) ? location.state.selectedTag : null,
            }).then(res => {
                console.log(res.data)

                localStorage.setItem('authToken', res.data.token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`
                navigate('/app/')

                // NO NEED TO LOGIN!
                // navigate('/login/', { state: { successRegistration: true } })
            }).catch(err => {
                console.log("Could not register user.");
                setOtherErrorMsg(err.response.data.error)
            })
        }
    }

    const changeValue = (event, type) => {

        var value = null;

        switch (event.target.id) {
            case "formBasicCheckbox":
                value = event.target.checked;
                break;
            case "formBasicPassword":
                value = event.target.value;
                break;
            case "formBasicEmail":
                value = event.target.value;
                break;
            case "formBasicName":
                value = event.target.value;
                break;
            default:
                break;
        }

        switch (type) {
            case "NAME":
                setName(value);
                break;
            case "EMAIL":
                setEmail(value);
                break;
            case "PASSWORD":
                setPassword(value);
                break;
            case "CONFIRM_PASSWORD":
                setConfirmPassword(value);
                break;
            case "TOS_PRIVACY":
                setTosPrivacyAccepted(value);
                break;
            default:
                break;
        }
    }


    return (
        <div>
            {/* Top */}
            <div id="top" />
            <div className={`${SiteStyles.ImageCenteredBG}`}>
                <div style={{ backgroundColor: 'white', borderRadius: 1000, zIndex: 1000, }}>
                    <img src={`${Config.IMAGES_BASE_URL}/stickers/3_512.png`}
                        style={{
                            width: '32vw', height: '32vw',
                            margin: -16,
                        }}
                        className={Styles.tinkeringImage}
                    />
                </div>
            </div>
            <div className={SiteStyles.LeftFloatingPane}>

                <div style={{
                    display: 'flex', justifyContent: 'space-between', alignItems: 'center',
                }}>
                    <p className={TextStyles.Title}>Register</p>
                    <p className={TextStyles.Body} style={{
                        margin: 0,
                    }}>Or, <a style={{
                        color: '#1d3799', fontWeight: 'bold',
                        backgroundColor: 'rgb(225,225,235)',
                        padding: 8, borderRadius: 5, textDecoration: 'none'
                    }} href="/login" >Log in</a></p>
                </div>
                <br />

                <Form onSubmit={submitForm}>
                    {/* Full Name */}
                    <Form.Group className="mb-3" controlId="formBasicName">
                        <p className={TextStyles.Body} style={{ margin: 0, fontWeight: '600' }}>Full Name</p>
                        <Form.Control className={TextStyles.Body} type="text" placeholder="Enter Full Name" value={name} onChange={e => changeValue(e, "NAME")} />
                    </Form.Group>

                    {/* Email */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <p className={TextStyles.Body} style={{ margin: 0, fontWeight: '600' }}>Email address</p>
                        <Form.Control className={TextStyles.Body} type="email" placeholder="Enter email" value={email} onChange={e => changeValue(e, "EMAIL")} />
                    </Form.Group>

                    {/* Password */}
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                        <p className={TextStyles.Body} style={{ margin: 0, fontWeight: '600' }}>Password</p>
                        <Form.Control className={TextStyles.Body} type={"password"} placeholder="Create a Password" value={password} onChange={e => changeValue(e, "PASSWORD")} />
                    </Form.Group>

                    {/* Confirm Password */}
                    {/* <Form.Group className="mb-3" controlId="formBasicPassword">
                        <p className={TextStyles.Body} style={{ margin: 0, fontWeight: '600' }}>Confirm Password</p>
                        <Form.Control className={TextStyles.Body} type={"password"} placeholder="Re-enter Password" value={confirmPassword} onChange={e => changeValue(e, "CONFIRM_PASSWORD")} />
                    </Form.Group> */}

                    <Form.Group className="mb-3">
                        <p className={TextStyles.Body} style={{ marginBottom: 4, fontWeight: '600' }}>Where Did You Hear About Us?</p>

                        <div style={{ display: 'flex', gap: 4, flexWrap: 'wrap' }}>
                            {/* YouTube Button */}
                            <div
                                onClick={() => setWhereDidYouHearAboutUs("YouTube")}
                                className={whereDidYouHearAboutUs === "YouTube" ? Styles.ReferrerBtnSelected : Styles.ReferrerBtn}>
                                <img src={`${Config.ICONS_BASE_URL}/color/youtube.png`} style={{ height: 14, width: 14 }} />
                                <p className={Styles.ReferrerBtnText} >YouTube</p>
                            </div>

                            {/* Google Button */}
                            <div
                                onClick={() => setWhereDidYouHearAboutUs("Google")}
                                className={whereDidYouHearAboutUs === "Google" ? Styles.ReferrerBtnSelected : Styles.ReferrerBtn}>
                                <img src={`${Config.ICONS_BASE_URL}/color/google.png`} style={{ height: 14, width: 14 }} />
                                <p className={Styles.ReferrerBtnText} >Google</p>
                            </div>

                            {/* Instagram Button */}
                            <div
                                onClick={() => setWhereDidYouHearAboutUs("Instagram")}
                                className={whereDidYouHearAboutUs === "Instagram" ? Styles.ReferrerBtnSelected : Styles.ReferrerBtn}>
                                <img src={`${Config.ICONS_BASE_URL}/color/instagram.png`} style={{ height: 14, width: 14 }} />
                                <p className={Styles.ReferrerBtnText} >Instagram</p>
                            </div>

                            {/* TikTok Button */}
                            <div
                                onClick={() => setWhereDidYouHearAboutUs("TikTok")}
                                className={whereDidYouHearAboutUs === "TikTok" ? Styles.ReferrerBtnSelected : Styles.ReferrerBtn}>
                                <img src={`${Config.ICONS_BASE_URL}/color/tik-tok.png`} style={{ height: 14, width: 14 }} />
                                <p className={Styles.ReferrerBtnText} >TikTok</p>
                            </div>

                            {/* Reddit Button */}
                            {/* <div
                                onClick={() => setWhereDidYouHearAboutUs("Reddit")}
                                className={whereDidYouHearAboutUs === "Reddit" ? Styles.ReferrerBtnSelected : Styles.ReferrerBtn}>
                                <img src={`${Config.ICONS_BASE_URL}/color/reddit.png`} style={{ height: 14, width: 14 }} />
                                <p className={Styles.ReferrerBtnText} >Reddit</p>
                            </div> */}

                            {/* Friend Button */}
                            <div
                                onClick={() => setWhereDidYouHearAboutUs("Friend")}
                                className={whereDidYouHearAboutUs === "Friend" ? Styles.ReferrerBtnSelected : Styles.ReferrerBtn}>
                                <img src={`${Config.ICONS_BASE_URL}/color/friends.png`} style={{ height: 14, width: 14 }} />
                                <p className={Styles.ReferrerBtnText} >Friend</p>
                            </div>

                            {/* Other Button */}
                            <div
                                onClick={() => setWhereDidYouHearAboutUs("Other")}
                                className={whereDidYouHearAboutUs === "Other" ? Styles.ReferrerBtnSelected : Styles.ReferrerBtn}>
                                <img src={`${Config.ICONS_BASE_URL}/color/more.png`} style={{ height: 14, width: 14 }} />
                                <p className={Styles.ReferrerBtnText} >Other</p>
                            </div>
                        </div>

                        {whereDidYouHearAboutUs === "Other" && (
                            <Form.Control
                                style={{ marginTop: 8 }}
                                className={TextStyles.Body}
                                type="text"
                                placeholder="Please specify"
                                value={otherSource}
                                onChange={e => setOtherSource(e.target.value)}
                            />
                        )}
                    </Form.Group>


                    {/* AGREE TO TOS AND PRIVACY POLICY */}
                    <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check
                            className={TextStyles.Body}
                            onChange={e => changeValue(e, "TOS_PRIVACY")}
                            label={
                                <span>
                                    I agree to the <Link to="/tos" style={{ color: '#1e3799', fontWeight: 'bold', textDecoration: 'none' }}>Terms of Service </Link> and <Link to="/privacy" style={{ color: '#1e3799', fontWeight: 'bold', textDecoration: 'none' }}>Privacy Policy</Link>.
                                </span>
                            }
                        />
                    </Form.Group>


                    <div>

                        {/* SUBMIT BUTTON */}
                        <button className={SiteStyles.ActionButton} type="submit" >
                            Submit
                        </button>

                        {nameErrorMsg == null ? null : ErrorItem(nameErrorMsg.toString())}
                        {emailErrorMsg == null ? null : ErrorItem(emailErrorMsg.toString())}
                        {passwordErrorMsg == null ? null : ErrorItem(passwordErrorMsg.toString())}
                        {confirmPasswordErrorMsg == null ? null : ErrorItem(confirmPasswordErrorMsg.toString())}
                        {tosErrorMsg == null ? null : ErrorItem(tosErrorMsg.toString())}
                        {otherErrorMsg == null ? null : ErrorItem(otherErrorMsg.toString())}


                    </div>
                </Form>
            </div >
        </div >
    );
}

const mapStateToProps = state => {
    return {
        URLRef: state.URLRef,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIsAuthenticated: (val) => dispatch({ type: "SET_AUTHENTICATION", payload: { value: val } }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Register);
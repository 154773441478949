import React, { useRef, useEffect } from 'react';
import SiteStyles from '../../Site.module.css';

const handleChange = (event, setState) => {
    setState(event.target.value);
}

// Use object destructuring in the function parameters to directly extract the props
const TextInput = ({ label, placeholder, value, onChange, width = 240, disabled = false, hideLabel = false, backgroundColor = null }) => {
    return (
        <div>
            {hideLabel !== true && <p className={SiteStyles.ModernInputFieldLabel} style={{ margin: 0 }}>{`${label} ${disabled ? "(Disabled)" : ""}`}</p>}
            <input
                type="text"
                placeholder={placeholder}
                value={value}
                onChange={event => handleChange(event, onChange)}
                className={SiteStyles.ModernInputField}
                style={{ marginBottom: 8, width, backgroundColor: backgroundColor, borderColor: '#aaa' }}
                disabled={disabled}
            />
        </div>
    );
};

const TextAreaInput = ({ label, placeholder, value, onChange, width = '100%', disabled = false, height = 'auto', hideLabel = false, backgroundColor = null }) => {
    return (
        <div>
            {hideLabel !== true && <p className={SiteStyles.ModernInputFieldLabel} style={{ margin: 0 }}>{`${label} ${disabled ? "(Disabled)" : ""}`}</p>}
            <textarea
                placeholder={placeholder}
                className={SiteStyles.ModernInputField}
                style={{ marginBottom: 8, width: width, height: height, backgroundColor: backgroundColor, borderColor: '#aaa' }}
                value={value}
                onChange={(event) => handleChange(event, onChange)}
                disabled={disabled}
            />
        </div>
    )
}

const TextAreaInputWrap = ({
    label,
    placeholder,
    value,
    onChange,
    width = '100%',
    disabled = false,
    height = 'auto',
    hideLabel = false,
    backgroundColor = null
}) => {
    const textAreaRef = useRef(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey || event.metaKey) {
                let tag = '';
                if (event.key === '1') {
                    tag = '1';
                } else if (event.key === '2') {
                    tag = '2';
                } else if (event.key === '3') {
                    tag = '3';
                } else if (event.key === '4') {
                    tag = '4';
                } else if (event.key === '5') {
                    tag = '5';
                } else if (event.key === '6') {
                    tag = '6';
                }

                if (tag) {
                    event.preventDefault();
                    wrapSelectedText(tag);
                }
            }
        };

        const textArea = textAreaRef.current;
        textArea.addEventListener('keydown', handleKeyDown);

        return () => {
            textArea.removeEventListener('keydown', handleKeyDown);
        };
    }, [value]);

    const wrapSelectedText = (tag) => {
        const textArea = textAreaRef.current;
        const start = textArea.selectionStart;
        const end = textArea.selectionEnd;
        const selectedText = value.substring(start, end);
        const newText = value.substring(0, start) + `<${tag}>` + selectedText + `</${tag}>` + value.substring(end);
        onChange(newText);

        // Set cursor position after the inserted tag
        setTimeout(() => {
            textArea.selectionStart = start + tag.length + 2; // <tag>
            textArea.selectionEnd = end + tag.length + 2;
            textArea.focus();
        }, 0);
    };

    const handleChange = (event) => {
        onChange(event.target.value);
    };

    return (
        <div>
            {!hideLabel && <p className={SiteStyles.ModernInputFieldLabel} style={{ margin: 0 }}>{`${label} ${disabled ? "(Disabled)" : ""}`}</p>}
            <textarea
                ref={textAreaRef}
                placeholder={placeholder}
                className={SiteStyles.ModernInputField}
                style={{ marginBottom: 8, width: width, height: height, backgroundColor: backgroundColor }}
                value={value}
                onChange={handleChange}
                disabled={disabled}
            />
        </div>
    );
};

const DualSegmentedControlInput = ({ label, choiceA, choiceB, value, onChange, disabled = false, selectedColor = 'black', selectedBackgroundColor = 'white' }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: 16, justifyContent: 'space-between' }}>
            <p className={SiteStyles.ModernInputFieldLabel} style={{ margin: 0, }}>{`${label} ${disabled ? "(Disabled)" : ""}`}</p>
            <div>
                <div className={SiteStyles.SegmentedControlBackground}>
                    <button className={value ? SiteStyles.SegmentedControlSelected : SiteStyles.SegmentedControl}
                        disabled={disabled}
                        onClick={(e) => { e.preventDefault(); onChange(true) }}
                        style={{ color: value ? selectedColor : null, background: value ? selectedBackgroundColor : null }}>
                        {choiceA}
                    </button>
                    <button className={!value ? SiteStyles.SegmentedControlSelected : SiteStyles.SegmentedControl}
                        disabled={disabled}
                        onClick={(e) => { e.preventDefault(); onChange(false) }}>
                        {choiceB}
                    </button>
                </div>
            </div>
        </div>
    )
}

const MultipleSegmentedControlInput = ({ label, options, value, onChange, disabled = false, vertical = false, hideLabel = false, thin = false }) => {
    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: vertical ? 8 : 16, justifyContent: 'space-between', flexDirection: vertical ? 'column' : 'row' }}>
            {!hideLabel && <p className={SiteStyles.ModernInputFieldLabel} style={{ margin: 0, }}>{`${label} ${disabled ? "(Disabled)" : ""}`}</p>}
            <div>
                <div className={thin ? SiteStyles.SegmentedControlBackgroundThin : SiteStyles.SegmentedControlBackground} >
                    {options.map(x => {
                        return (<button className={value === x ?
                            thin ?
                                SiteStyles.SegmentedControlSelectedThin
                                : SiteStyles.SegmentedControlSelected
                            :
                            thin ?
                                SiteStyles.SegmentedControlThin
                                : SiteStyles.SegmentedControl
                        }
                            disabled={disabled} onClick={(e) => { e.preventDefault(); onChange(x); }}                        >
                            {x}
                        </button>)
                    })}
                </div>
            </div>
        </div>
    )
}

export { TextInput, TextAreaInput, TextAreaInputWrap, DualSegmentedControlInput, MultipleSegmentedControlInput };
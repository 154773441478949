import React, { useState, useEffect } from 'react';
import Config from '../../../config.json'
import axios from 'axios';
import { ChatIcon, ChevronDownIcon, SearchIcon, XIcon } from '../../Icons';
import Styles from './SlimFilterBar.module.css';
import ButtonStyles from '../../styles/buttons.module.css'
import { Container } from 'react-bootstrap';

const SlimFilterBar = ({ onSearch, onFilterChange }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedTag, setSelectedTag] = useState('');
  const [selectedSubTag, setSelectedSubTag] = useState('');
  const [tags, setTags] = useState([]);
  const [subTags, setSubTags] = useState([]);
  const [isTagDropdownOpen, setIsTagDropdownOpen] = useState(false);
  const [isSubTagDropdownOpen, setIsSubTagDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Add listener for the custom urlChange event
  useEffect(() => {
    const handleUrlChange = () => {
      const params = new URLSearchParams(window.location.search);
      const urlTag = params.get('tag');
      const urlSubtag = params.get('subtag');
      const urlSearch = params.get('search');

      setSearchTerm(urlSearch || '');
      if (urlTag) {
        setSelectedTag(urlTag);
        fetchSubTags(urlTag).then(() => {
          if (urlSubtag) {
            setSelectedSubTag(urlSubtag);
          }
        });
      } else {
        setSelectedTag('');
        setSelectedSubTag('');
      }

      // Trigger the filter change
      onFilterChange({ tag: urlTag || '', subtag: urlSubtag || '' });
    };

    window.addEventListener('urlChange', handleUrlChange);
    window.addEventListener('popstate', handleUrlChange);

    return () => {
      window.removeEventListener('urlChange', handleUrlChange);
      window.removeEventListener('popstate', handleUrlChange);
    };
  }, [onFilterChange]);

  useEffect(() => {
    // Check mobile size for conditional render 
    const handleResize = () => { setIsMobile(window.innerWidth <= 768); };
    window.addEventListener('resize', handleResize);
    return () => { window.removeEventListener('resize', handleResize); };
  }, [])

  // Add this useEffect near your other useEffects
  useEffect(() => {
    const handleClickOutside = (event) => {
      // Check if click is outside the dropdowns and their buttons
      const dropdownElements = document.querySelectorAll(`.${Styles.dropdownWrapper}`);
      let isClickInside = false;

      dropdownElements.forEach(element => {
        if (element.contains(event.target)) {
          isClickInside = true;
        }
      });

      if (!isClickInside) {
        setIsTagDropdownOpen(false);
        setIsSubTagDropdownOpen(false);
      }
    };

    // Add the event listener to the document
    document.addEventListener('mousedown', handleClickOutside);

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    fetchTags();
    // Initialize state from URL parameters
    const params = new URLSearchParams(window.location.search);
    const urlTag = params.get('tag');
    const urlSubtag = params.get('subtag');
    const urlSearch = params.get('search');

    if (urlSearch) setSearchTerm(urlSearch);
    if (urlTag) {
      setSelectedTag(urlTag);
      // Fetch subtags immediately if we have a tag from URL
      fetchSubTags(urlTag).then(() => {
        // Set the subtag after fetching subtags
        if (urlSubtag) {
          setSelectedSubTag(urlSubtag);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (selectedTag) {
      fetchSubTags(selectedTag);
    } else {
      setSubTags([]);
      setSelectedSubTag('');
    }
  }, [selectedTag]);

  const fetchTags = async () => {
    try {
      const response = await axios.post(`${Config.API_BASE_URL}/public/tags`, { showHidden: true });
      setTags(response.data.tags);
    } catch (error) {
      console.error('Error fetching tags:', error);
    }
  };

  const fetchSubTags = async (tagId) => {
    try {
      const response = await axios.get(`${Config.API_BASE_URL}/public/subtags/${tagId}`);
      setSubTags(response.data.subtags);
    } catch (error) {
      console.error('Error fetching subtags:', error);
    }
  };

  const handleSearch = () => {
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('search', searchTerm);
    queryParams.set('page', '1');
    window.history.pushState(null, '', `?${queryParams.toString()}`);
    onSearch(searchTerm);
  };

  const handleTagChange = (tag) => {
    setSelectedTag(tag);
    setIsTagDropdownOpen(false);
    setSearchTerm(''); // Clear search term

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('tag', tag);
    queryParams.delete('subtag');
    queryParams.delete('search'); // Remove search from URL
    queryParams.set('page', '1');
    window.history.pushState(null, '', `?${queryParams.toString()}`);
    onFilterChange({ tag, subtag: '' });
  };

  const handleSubTagChange = (subtag) => {
    setSelectedSubTag(subtag);
    setIsSubTagDropdownOpen(false);
    setSearchTerm(''); // Clear search term

    const queryParams = new URLSearchParams(window.location.search);
    queryParams.set('subtag', subtag);
    queryParams.delete('search'); // Remove search from URL
    queryParams.set('page', '1');
    window.history.pushState(null, '', `?${queryParams.toString()}`);
    onFilterChange({ tag: selectedTag, subtag });
  };

  const handleClearSearch = () => {
    setSearchTerm('');
    const queryParams = new URLSearchParams(window.location.search);
    queryParams.delete('search');
    window.history.pushState(null, '', `?${queryParams.toString()}`);
    onSearch('');
  };

  const handleTagDropdownClick = () => {
    setIsSubTagDropdownOpen(false); // Close subtag dropdown
    setIsTagDropdownOpen(!isTagDropdownOpen);
  };

  const handleSubTagDropdownClick = () => {
    setIsTagDropdownOpen(false); // Close tag dropdown
    setIsSubTagDropdownOpen(!isSubTagDropdownOpen);
  };

  return (
    <div className={Styles.filterBar}>
      <Container>

        <div className={Styles.filterContainer}>
          {/* Tag Dropdown */}
          <div className={Styles.dropdownWrapper}>
            <div className={Styles.dropdownButtonContainer}>
              <button
                onClick={handleTagDropdownClick}
                className={Styles.dropdownButton}
              >
                <span className={Styles.buttonText}>
                  {selectedTag ? tags.find(t => t.id === selectedTag)?.title : 'Select Category'}
                </span>
                <ChevronDownIcon size={16} className="text-gray-400" />
              </button>
              {selectedTag && (
                <button
                  onClick={() => {
                    setSelectedTag('');
                    setSelectedSubTag('');
                    const queryParams = new URLSearchParams(window.location.search);
                    queryParams.delete('tag');
                    queryParams.delete('subtag');
                    queryParams.set('page', '1');
                    window.history.pushState(null, '', `?${queryParams.toString()}`);
                    onFilterChange({ tag: '', subtag: '' });
                  }}
                  className={Styles.clearDropdownButton}
                >
                  <XIcon size={16} strokeWidth={3} mb={2} />
                </button>
              )}
            </div>
            {isTagDropdownOpen && (
              <div className={Styles.dropdownMenu}>
                {tags.map(tag => (
                  <button
                    key={tag.id}
                    onClick={() => handleTagChange(tag.id)}
                    className={Styles.dropdownItem}
                  >
                    {tag.title}
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* SubTag Dropdown */}
          <div className={Styles.dropdownWrapper}>
            <div className={Styles.dropdownButtonContainer}>
              <button
                onClick={handleSubTagDropdownClick}
                disabled={!selectedTag}
                className={Styles.dropdownButton}
              >
                <span className={Styles.buttonText}>
                  {selectedSubTag ? subTags.find(st => st.id === selectedSubTag)?.title : 'Select Subcategory'}
                </span>
                <ChevronDownIcon size={16} className="text-gray-400" />
              </button>
              {selectedSubTag && (
                <button
                  onClick={() => {
                    setSelectedSubTag('');
                    const queryParams = new URLSearchParams(window.location.search);
                    queryParams.delete('subtag');
                    queryParams.set('page', '1');
                    window.history.pushState(null, '', `?${queryParams.toString()}`);
                    onFilterChange({ tag: selectedTag, subtag: '' });
                  }}
                  className={Styles.clearDropdownButton}
                >
                  <XIcon size={16} strokeWidth={3} mb={2} />
                </button>
              )}
            </div>
            {isSubTagDropdownOpen && (
              <div className={Styles.dropdownMenu}>
                {subTags.map(subtag => (
                  <button
                    key={subtag.id}
                    onClick={() => handleSubTagChange(subtag.id)}
                    className={Styles.dropdownItem}
                  >
                    {subtag.title}
                  </button>
                ))}
              </div>
            )}
          </div>

          {/* Search Bar */}
          <div className={Styles.searchContainer}>
            <div className={Styles.searchInputWrapper}>
              <input
                type="text"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Search name, school, etc."
                className={Styles.searchInput}
                onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
              />
              <div className={searchTerm ? Styles.XButton : Styles.searchIcon}
                onClick={() => { if (searchTerm) { handleClearSearch() } }}>

                {
                  searchTerm ?
                    <XIcon size={16} strokeWidth={3} mb={2} ml={1} color={"#404040"} />
                    :
                    <SearchIcon width={17} height={17} mb={4} mr={2} strokeWidth={3} color={"#404040"} />
                }


              </div>
            </div>


            {/* Search Button */}
            <button onClick={handleSearch} className={ButtonStyles.StandardGrey} style={{ padding: '0px 16px', height: 32, }}>Search</button>

          </div>
        </div>

      </Container>
    </div>
  );
};

export default SlimFilterBar;
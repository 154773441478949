import React, { useTransition } from 'react';
import { Navbar, NavDropdown, Nav, Container } from 'react-bootstrap'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SiteStyles from '../Site.module.css'
import Styles from './NavBar.module.css'
import { useNavigate } from 'react-router-dom';
import Config from '../../config.json'


const NavBar = ({ mainColor = '#1e3799', isAuthenticated }) => {
    let navigate = useNavigate();
    const isAdmissionPeer = window.location.hostname === 'admissionpeer.com' || window.location.hostname === 'www.admissionpeer.com';
    const [isPending, startTransition] = useTransition();

    const handleNavigation = () => {
        startTransition(() => {
            navigate('/app');
        });
    };

    // Check if the domain is 'admissionpeer.com'
    const logoSrc = isAdmissionPeer ?
        Config.IMAGES_BASE_URL + '/admissionpeer_black_smalltext_4.png' // Custom logo for admissionpeer.com
        : Config.IMAGES_BASE_URL + '/logo_tbd.png'; // Default logo

    return (
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light" >
            <Container>
                <Navbar.Brand className={Styles.HoverImage} onClick={() => { navigate('/') }}>
                    <img src={logoSrc} style={{ height: 50, width: isAdmissionPeer ? 200 : 150, }} />
                </Navbar.Brand>

                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                        {
                            !isAdmissionPeer &&
                            <>
                                <Nav.Link className={Styles.DropDownMenuText} onClick={() => navigate('/products/medinterview')} style={{ fontSize: 'large', color: 'black', fontWeight: '600' }}>Medical</Nav.Link>
                                <Nav.Link className={Styles.DropDownMenuText} onClick={() => navigate('/products/casper')} style={{ fontSize: 'large', color: 'black', fontWeight: '600' }}>CASPer®</Nav.Link>
                                <Nav.Link className={Styles.DropDownMenuText} onClick={() => navigate('/products/aamcpreview')} style={{ fontSize: 'large', color: 'black', fontWeight: '600' }}>PREview™</Nav.Link>
                            </>
                        }
                        {
                            isAdmissionPeer &&
                            <>
                                <Nav.Link className={Styles.DropDownMenuText} href="https://admissionpeer.com/article/become-consultant" target="_blank" style={{ fontSize: 'large', color: 'black', fontWeight: '600' }}>Become a Consultant</Nav.Link>
                            </>
                        }
                    </Nav>
                    <Nav>
                        <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
                            <Link to="/blog" style={{
                                fontFamily: 'Source Sans pro', marginBottom: 6, fontSize: 16,
                                textDecoration: 'none', padding: '0px 10px', color: mainColor,
                                fontWeight: '600'
                            }}>Blog</Link>
                        </div>

                        {
                            !isAdmissionPeer &&
                            <>
                                <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
                                    <Link to="/marketplace" style={{
                                        fontFamily: 'Source Sans pro', marginBottom: 6, fontSize: 16,
                                        textDecoration: 'none', padding: '0px 10px', color: mainColor,
                                        fontWeight: '600'
                                    }}>Marketplace</Link>
                                </div>
                            </>
                        }

                        <div style={{ height: 40, display: 'flex', alignItems: 'center' }}>
                            <Link to="/interview" style={{
                                fontFamily: 'Source Sans pro', marginBottom: 6, fontSize: 16,
                                textDecoration: 'none', padding: '0px 10px', color: mainColor,
                                fontWeight: '600'
                            }}>Schools</Link>
                        </div>

                        {isAuthenticated ? (
                            <button
                                className={SiteStyles.ActionButtonSlim}
                                onClick={handleNavigation}
                                disabled={isPending}
                            >
                                {isPending ? 'Loading...' : isAdmissionPeer ? 'Access →' : 'Go To Library →'}
                            </button>
                        ) :
                            <div>

                                <button className={SiteStyles.ActionButtonSlim}
                                    style={{ backgroundColor: 'rgb(230,230,240)', color: 'black', marginRight: 8 }}
                                    onClick={() => { navigate('/login'); }}>Sign in</button>

                                <button className={SiteStyles.ActionButtonSlim}
                                    onClick={() => { navigate('/register'); }}>Register</button>
                            </div>
                        }

                    </Nav>
                </Navbar.Collapse>
            </Container >
        </Navbar >
    );
}


// Function to map state from store to props
const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.isAuthenticated,
    };
};

// Use the connect higher-order component to connect NavBar to the Redux store
export default connect(mapStateToProps)(NavBar);
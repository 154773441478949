import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TextStyles from '../TextStyles.module.css';
import Styles from './Survey.module.css';
import axios from 'axios';
import Config from '../../config.json'
import NavBar from '../NavBar/NavBar';
import Footer from '../Footer/Footer';
import { ClockIcon, MailIcon } from '../../components/Icons'
import { MultipleSegmentedControlInput, DualSegmentedControlInput } from '../Course/Subcomponents/text-inputs'

const Survey = () => {
    const navigate = useNavigate();
    const [validated, setValidated] = useState(false);
    const [errors, setErrors] = useState([]);

    const [formData, setFormData] = useState({
        name: '',
        medicalSchool: '',
        email: '',
        year: '',
        aiFamiliarity: '',
        scopeFamiliarity: '',
        aiImpact: '',
        scopeExpansion: '',
        lessSpecialties: '',
        moreSpecialties: '',
        comments: '',

        // AI Questions 

        aiBenefits: [],
        otherAiBenefits: '',
        aiDrawbacks: [],
        otherAiDrawbacks: '',
        impactedSpecialties: [],
        otherImpactedSpecialties: '',

        lessConsideredSpecialtiesExist: false,
        notConsideredSpecialties: [],
        otherNotConsideredSpecialties: '',

        moreConsideredSpecialtiesExist: false,
        moreConsideredSpecialties: [],
        otherMoreConsideredSpecialties: '',

        // APP Questions
        appImpact: '',
        appBenefits: [],
        otherAppBenefits: '',
        appDrawbacks: [],
        otherAppDrawbacks: '',
        appImpactedSpecialties: [],
        appOtherImpactedSpecialties: '',

        appLessConsideredSpecialtiesExist: false,
        appNotConsideredSpecialties: [],
        appOtherNotConsideredSpecialties: '',

        appMoreConsideredSpecialtiesExist: false,
        appMoreConsideredSpecialties: [],
        appOtherMoreConsideredSpecialties: '',

        aiScared: '',
        appScared: '',
        biggerThreat: '',

        aiPreparedness: '',
        appPreparedness: '',
        studentPreparation: '',
    });

    const specialtyList = [
        // Non-surgical specialties, alphabetized
        'Allergy and immunology',
        'Anesthesiology',
        'Dermatology',
        'Diagnostic radiology',
        'Emergency medicine',
        'Family medicine',
        'Internal medicine',
        'Interventional Radiology',
        'Medical genetics',
        'Neurology',
        'Nuclear medicine',
        'Obstetrics and gynecology',
        'Pathology',
        'Pediatrics',
        'Physical medicine and rehabilitation',
        'Preventive medicine',
        'Psychiatry',
        'Radiation oncology',

        // Surgical specialties, prefixed and alphabetized
        '[SURGERY] Otolaryngology',
        '[SURGERY] General Surgery',
        '[SURGERY] Neurosurgery',
        '[SURGERY] Obstetrics & Gynecology ',
        '[SURGERY] Ophthalmology',
        '[SURGERY] Orthopedic surgery',
        '[SURGERY] Plastic Surgery',
        '[SURGERY] Urology',

        'Other',
    ];

    const submitAnswers = () => {
        axios.post(`${Config.API_BASE_URL}/public/survey-submission`, { formData }).then((res) => {
            navigate('/survey-thank-you', { state: { completedForm: true } })
        }).catch((err) => {
            console.log(`Error submitting survey: ${err}`)
        })
    }


    // Handle form input changes
    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            let updatedSelection = [...formData[name]];
            if (checked) {
                updatedSelection.push(value);
            } else {
                updatedSelection = updatedSelection.filter((item) => item !== value);
            }
            setFormData({ ...formData, [name]: updatedSelection });
        } else {
            setFormData({ ...formData, [name]: value });
        }
    };

    // Handle form submission
    const handleSubmit = async (event) => {
        const form = event.currentTarget;
        event.preventDefault();
        submitAnswers();
        setValidated(true);
    };


    return (
        <div style={{
            padding: '32px 8px',
            background: 'linear-gradient(135deg, rgba(245, 245, 255, 1) 0%, rgba(255, 228, 240, 1) 25%, rgba(255, 255, 225, 1) 50%, rgba(225, 240, 255, 1) 75%, rgba(240, 255, 245, 1) 100%)',
            fontFamily: 'Source Sans Pro',
        }}>
            <Container>
                <div className="text-center">
                    <p style={{ textTransform: 'uppercase', marginBottom: 0, fontWeight: '700' }}>The New Debate</p>
                    <h1 className={TextStyles.Title}>AI vs. Scope Expansion</h1>
                    <p className={TextStyles.Body} style={{ color: 'black' }}>
                        Understanding a Generation of Future Physicians
                    </p>
                    <div style={{ display: 'flex', marginBottom: 16, justifyContent: 'center', width: '100%', gap: 4, flexWrap: 'wrap' }}>
                        <p style={{
                            backgroundColor: '#341f9722', color: '#341f97', fontWeight: '600',
                            borderRadius: 5, padding: '2px 8px', marginBottom: 0
                        }}><ClockIcon width={17} height={17} color={'#341f97'} /> 5 min</p>
                        {/* <p style={{
                            backgroundColor: '#341f9722', color: '#341f97', fontWeight: '600',
                            borderRadius: 5, padding: '2px 8px', marginBottom: 0
                        }}><MailIcon width={17} height={17} color={'#341f97'} /> info@brightstudent.org</p> */}
                        <p style={{
                            backgroundColor: '#341f9722', color: '#341f97', fontWeight: '600',
                            borderRadius: 5, padding: '2px 8px', marginBottom: 0
                        }}>Key results shown after form completion</p>
                    </div>
                    {/* <p><i>Contact info@brightstudent.org with questions / technical issues</i></p> */}
                </div>

                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <div style={{ backgroundColor: 'white', border: '1px solid rgb(200,200,210)', borderRadius: 5, padding: 16, marginBottom: 8 }}>
                        <div style={{ display: 'flex', gap: 8, marginBottom: -4 }}>
                            <h6 style={{ color: 'white', backgroundColor: 'black', padding: '4px 8px', borderRadius: 5, fontWeight: 'bold', }}>1/5</h6>
                            <h5 style={{ fontWeight: '700' }}>Nice to Meet You!</h5>
                        </div>
                        <p style={{ fontWeight: '600', marginBottom: 2 }}>Let's make sure you're a real medical student, so your opinion counts.</p>
                        <p style={{ fontWeight: '500', marginBottom: 12 }}><i>Your answers will be <em>anonymized</em></i></p>


                        {/* NAME */}
                        <Form.Group controlId="name" style={{ marginBottom: 6 }}>
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                Full Name</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter your name"
                                name="name"
                                value={formData.name}
                                onChange={handleChange}
                                style={{ padding: 4, }}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide your full name.
                            </Form.Control.Feedback>
                        </Form.Group>


                        {/* MEDICAL SCHOOL */}
                        <Form.Group controlId="medicalSchool" style={{ marginBottom: 6 }}>
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                Medical School</Form.Label>
                            <Form.Control
                                required
                                type="text"
                                placeholder="Enter your medical school"
                                name="medicalSchool"
                                value={formData.medicalSchool}
                                onChange={handleChange}
                                style={{ padding: 4, }}

                            />
                            <Form.Control.Feedback type="invalid">
                                Please provide your medical school.
                            </Form.Control.Feedback>
                        </Form.Group>

                        {/* EMAIL */}
                        <Form.Group controlId="email" className="mb-3" style={{ marginBottom: 0 }}>
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                School Email</Form.Label>
                            <Form.Control
                                required
                                type="email"
                                placeholder="Enter your .edu email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                style={{ padding: 4, }}

                            />
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase', color: '#1B1464' }}>
                                We are currently not paying students from <b>UCSD</b>, <b>Temple</b>, <b>South Alabama</b> or <b>Mayo Clinic</b> [Too many responses]</Form.Label>
                            <Form.Control.Feedback type="invalid">
                                Please provide a valid .edu email.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="email" className="mb-3" style={{ marginBottom: 0 }}>
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                Year</Form.Label>
                            <MultipleSegmentedControlInput
                                label="Current Year"
                                options={['M1', 'M2', 'M3', 'M4', 'RY', 'MD/PhD']}
                                value={formData.year}
                                onChange={(value) => { setFormData({ ...formData, year: value }); }}
                                thin={true}
                                hideLabel={true}
                            />
                        </Form.Group>
                    </div>

                    <div style={{ backgroundColor: 'white', border: '1px solid rgb(200,200,210)', borderRadius: 5, padding: 16, marginBottom: 8 }}>

                        <div style={{ display: 'flex', gap: 8, marginBottom: -4 }}>
                            <h6 style={{ color: 'white', backgroundColor: 'black', padding: '4px 8px', borderRadius: 5, fontWeight: 'bold', }}>2/5</h6>
                            <h5 style={{ fontWeight: '700' }}>Familiarity</h5>
                        </div>

                        <p style={{ fontWeight: '600', marginBottom: 2 }}>How familiar are you with the current and foreseen impacts of Artificial Intelligence (AI) in medicine?</p>

                        <p style={{ fontWeight: '500', marginBottom: 2 }}><i>1 = Not Familiar; 5 = Very Familiar</i></p>

                        <Form.Group controlId="aiFamiliarity" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                Familiarity</Form.Label>

                            <MultipleSegmentedControlInput
                                options={['1', '2', '3', '4', '5']}
                                value={formData.aiFamiliarity}
                                onChange={(value) => {
                                    setFormData({ ...formData, aiFamiliarity: value });
                                }}
                                hideLabel={true}
                            />

                            <Form.Control.Feedback type="invalid">
                                Please select your familiarity with AI.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <p style={{ fontWeight: '500', marginBottom: 6 }}><i>A familiarity of 5 (Very Familiar) would imply having a deep understanding of AI's role in healthcare, possibly with hands-on experience building or deploying AI models in medical applications.</i></p>
                        <p style={{ fontWeight: '500', marginBottom: 2 }}><i>For a 1 (Not Familiar) rating, the person may only have heard of AI in general terms but lacks detailed knowledge of its specific uses or potential in medicine.</i></p>
                        <br />

                        <p style={{ fontWeight: '600', marginBottom: 2 }}>How familiar are you with the current and foreseen impacts of Advanced Practice Provider (APP) scope expansion?</p>
                        <p style={{ fontWeight: '500', marginBottom: 2 }}><i>1 = Not Familiar; 5 = Very Familiar</i></p>


                        <Form.Group controlId="scopeFamiliarity" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                Familiarity</Form.Label>
                            <MultipleSegmentedControlInput
                                options={['1', '2', '3', '4', '5']}
                                value={formData.scopeFamiliarity}
                                onChange={(value) => {
                                    setFormData({ ...formData, scopeFamiliarity: value });
                                }}
                                hideLabel={true}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please select your familiarity with advanced-care practitioner scope expansion.
                            </Form.Control.Feedback>
                        </Form.Group>
                        <p style={{ fontWeight: '500', marginBottom: 2 }}><i>Examples of APPs include Nurse Practitioners and Physician Assistants, where scope expansion refers to increasing their autonomy and responsibilities, such as diagnosing, prescribing medications, and managing patient care independently of physicians.</i></p>

                    </div>

                    <div style={{ backgroundColor: 'white', border: '1px solid rgb(200,200,210)', borderRadius: 5, padding: 16, marginBottom: 8 }}>
                        <div style={{ display: 'flex', gap: 8, marginBottom: -4 }}>
                            <h6 style={{ color: 'white', backgroundColor: 'black', padding: '4px 8px', borderRadius: 5, fontWeight: 'bold', }}>3/5</h6>
                            <h5 style={{ fontWeight: '700' }}>Your Opinion on AI</h5>
                        </div>
                        <p style={{ fontWeight: '600', marginBottom: 12 }}>What do you think about the use of AI in practice? Will it affect your residency choice?</p>


                        <Form.Group controlId="aiImpact" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                How do you think AI will Impact your Future Role?</Form.Label>
                            <p style={{ fontWeight: '500', marginBottom: 2 }}><i>1 = Significanty dimish; 5 = Significantly enhance</i></p>
                            <MultipleSegmentedControlInput
                                options={['1', '2', '3', '4', '5']}
                                value={formData.aiImpact}
                                onChange={(value) => {
                                    setFormData({ ...formData, aiImpact: value });
                                }}
                                hideLabel={true}
                            />

                            <Form.Control.Feedback type="invalid">
                                Please select how you think AI will impact your role.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="aiBenefits" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                What AI Benefits are you most Excited for?
                            </Form.Label>
                            {[
                                'Improved diagnostic accuracy',
                                'Enhanced patient care',
                                'Increased efficiency in administrative tasks',
                                'Personalized treatment plans',
                                'Predictive analytics for disease prevention',
                                'Real-time decision support in surgery',
                                'Faster drug discovery and development',
                                'I do not see any benefits',
                                'Other'
                            ].map((option) => (
                                <Form.Check
                                    key={option}
                                    type="checkbox"
                                    label={option}
                                    name="aiBenefits"
                                    value={option}
                                    checked={formData.aiBenefits.includes(option)}
                                    onChange={handleChange}
                                />
                            ))}
                        </Form.Group>

                        {/* Conditionally display the text input for "Other" if selected */}
                        {formData.aiBenefits?.includes('Other') && (
                            <Form.Group controlId="otherAiBenefits" style={{ marginTop: 0, marginBottom: 12 }}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please specify other AI benefits"
                                    name="otherAiBenefits"
                                    value={formData.otherAiBenefits}
                                    onChange={handleChange}
                                    style={{ padding: 4 }}
                                />
                            </Form.Group>
                        )}


                        <Form.Group controlId="aiDrawbacks" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                What Possible AI Drawbacks concern you?</Form.Label>
                            {[
                                'Loss of human touch in patient care',
                                'Over-reliance on AI for decision making',
                                'Bias in AI algorithms',
                                'Job displacement for healthcare workers',
                                'Security and privacy risks',
                                'Errors or malfunctions in AI systems',
                                'High implementation costs',
                                'I do not have concerns about AI',
                                'Other'
                            ].map((option) => (
                                <Form.Check
                                    key={option}
                                    type="checkbox"
                                    label={option}
                                    name="aiDrawbacks"
                                    value={option}
                                    checked={formData.aiDrawbacks?.includes(option)} // Assuming you create a new field for aiDrawbacks
                                    onChange={handleChange}
                                />
                            ))}
                        </Form.Group>

                        {/* Conditionally display the text input for "Other" if selected */}
                        {formData.aiDrawbacks?.includes('Other') && (
                            <Form.Group controlId="otherAiDrawbacks" style={{ marginTop: 0, marginBottom: 12 }}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please specify other AI drawbacks"
                                    name="otherAiDrawbacks"
                                    value={formData.otherAiDrawbacks}
                                    onChange={handleChange}
                                    style={{ padding: 4 }}
                                />
                            </Form.Group>
                        )}

                        {/* NEW: Specialties Most Impacted by AI */}
                        <Form.Group controlId="impactedSpecialties" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                Which Specialties Do you think are most susceptible to being heavily replaced by AI?</Form.Label>
                            <Form.Label style={{ marginBottom: 0, fontWeight: '500', fontSize: 'small', }}>
                                These are specialties you anticipate will have a reduction in workforce because many (or all) components of that specialty may become automated with AI</Form.Label>
                            {specialtyList.map((specialty) => (
                                <Form.Check
                                    key={specialty}
                                    type="checkbox"
                                    label={specialty}
                                    name="impactedSpecialties"
                                    value={specialty}
                                    checked={formData.impactedSpecialties.includes(specialty)}
                                    onChange={handleChange}
                                />
                            ))}
                        </Form.Group>

                        {/* Conditionally display the text input for "Other" if selected */}
                        {formData.impactedSpecialties?.includes('Other') && (
                            <Form.Group controlId="otherImpactedSpecialties" style={{ marginTop: 0, marginBottom: 12 }}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please specify"
                                    name="otherImpactedSpecialties"
                                    value={formData.otherImpactedSpecialties}
                                    onChange={handleChange}
                                    style={{ padding: 4 }}
                                />
                            </Form.Group>
                        )}

                        {/* CONSIDERING LESS! */}

                        {/* NEW: Reconsidering Specialties Due to AI */}
                        <Form.Group controlId="lessConsideredSpecialtiesExist" className="mb-3">
                            <DualSegmentedControlInput hideLabel={true}
                                label={"Are There Any Specialties You're Considering LESS Favorably due to the foreseen impact of AI?"}
                                value={formData.lessConsideredSpecialtiesExist} choiceA={"Yes"} choiceB={"No"}
                                onChange={(target) => { setFormData({ ...formData, lessConsideredSpecialtiesExist: target }) }} />
                        </Form.Group>

                        {formData.lessConsideredSpecialtiesExist === true &&
                            <>
                                {/* Specialties not being considered */}
                                <Form.Group controlId="notConsideredSpecialties" className="mb-3">
                                    <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                        If so, which ones?</Form.Label>
                                    {specialtyList.map((specialty) => (
                                        <Form.Check
                                            key={specialty}
                                            type="checkbox"
                                            label={specialty}
                                            name="notConsideredSpecialties"
                                            value={specialty}
                                            checked={formData.notConsideredSpecialties.includes(specialty)}
                                            onChange={handleChange}
                                        />
                                    ))}
                                </Form.Group>

                                {/* Conditionally display the text input for "Other" if selected */}
                                {formData.notConsideredSpecialties?.includes('Other') && (
                                    <Form.Group controlId="otherNotConsideredSpecialties" style={{ marginTop: 0, marginBottom: 12 }}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please specify"
                                            name="otherNotConsideredSpecialties"
                                            value={formData.otherNotConsideredSpecialties}
                                            onChange={handleChange}
                                            style={{ padding: 4 }}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        }

                        {/* CONSIDERING MORE! */}


                        {/* NEW: Reconsidering Specialties Due to AI */}
                        <Form.Group controlId="moreConsideredSpecialtiesExist" className="mb-3">
                            <DualSegmentedControlInput hideLabel={true}
                                label={"Are There Any Specialties You're Considering MORE Favorably Due to the foreseen impact of AI?"}
                                value={formData.moreConsideredSpecialtiesExist} choiceA={"Yes"} choiceB={"No"}
                                onChange={(target) => { setFormData({ ...formData, moreConsideredSpecialtiesExist: target }) }} />
                        </Form.Group>

                        {formData.moreConsideredSpecialtiesExist === true &&
                            <>
                                {/* Specialties not being considered */}
                                <Form.Group controlId="moreConsideredSpecialties" className="mb-3">
                                    <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                        If so, which ones?</Form.Label>
                                    {specialtyList.map((specialty) => (
                                        <Form.Check
                                            key={specialty}
                                            type="checkbox"
                                            label={specialty}
                                            name="moreConsideredSpecialties"
                                            value={specialty}
                                            checked={formData.moreConsideredSpecialties.includes(specialty)}
                                            onChange={handleChange}
                                        />
                                    ))}
                                </Form.Group>

                                {/* Conditionally display the text input for "Other" if selected */}
                                {formData.moreConsideredSpecialties?.includes('Other') && (
                                    <Form.Group controlId="otherMoreConsideredSpecialties" style={{ marginTop: 0, marginBottom: 12 }}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please specify"
                                            name="otherMoreConsideredSpecialties"
                                            value={formData.otherMoreConsideredSpecialties}
                                            onChange={handleChange}
                                            style={{ padding: 4 }}
                                        />
                                    </Form.Group>
                                )}

                            </>
                        }
                    </div>

                    {/* Advanced Practice Providers */}

                    <div style={{ backgroundColor: 'white', border: '1px solid rgb(200,200,210)', borderRadius: 5, padding: 16, marginBottom: 8 }}>
                        <div style={{ display: 'flex', gap: 8, marginBottom: -4 }}>
                            <h6 style={{ color: 'white', backgroundColor: 'black', padding: '4px 8px', borderRadius: 5, fontWeight: 'bold', }}>4/5</h6>
                            <h5 style={{ fontWeight: '700' }}>Your Opinion on Advanced Practice Providers (APPs)</h5>
                        </div>
                        <p style={{ fontWeight: '600', marginBottom: 12 }}>What do you think about the effects of APP scope expansion in practice? Will it affect your residency choice?</p>


                        <Form.Group controlId="appImpact" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                How do you think APP Scope Expansion will Impact your Future Role?</Form.Label>

                            <p style={{ fontWeight: '500', marginBottom: 2 }}><i>1 = Significanty dimish; 5 = Significantly enhance</i></p>
                            <MultipleSegmentedControlInput
                                options={['1', '2', '3', '4', '5']}
                                value={formData.appImpact}
                                onChange={(value) => {
                                    setFormData({ ...formData, appImpact: value });
                                }}
                                hideLabel={true}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please select how you think Scope Expansion will impact your role.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="appBenefits" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                What Benefits of Scope Expansion are you most Excited for?
                            </Form.Label>
                            {[
                                'Increased access to care',
                                'Enhanced patient care',
                                'Reduced physician workload',
                                'Improved efficiency in healthcare delivery',
                                'Lower healthcare costs',
                                'Addressing healthcare provider shortages',
                                'More personalized patient care',
                                'I do not see any benefits',
                                'Other'
                            ].map((option) => (
                                <Form.Check
                                    key={option}
                                    type="checkbox"
                                    label={option}
                                    name="appBenefits"
                                    value={option}
                                    checked={formData.appBenefits.includes(option)}
                                    onChange={handleChange}
                                />
                            ))}
                        </Form.Group>

                        {/* Conditionally display the text input for "Other" if selected */}
                        {formData.appBenefits?.includes('Other') && (
                            <Form.Group controlId="otherAppBenefits" style={{ marginTop: 0, marginBottom: 12 }}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please specify other APP benefits"
                                    name="otherAppBenefits"
                                    value={formData.otherAppBenefits}
                                    onChange={handleChange}
                                    style={{ padding: 4 }}
                                />
                            </Form.Group>
                        )}

                        <Form.Group controlId="appDrawbacks" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                What Possible Drawbacks of Scope Expansion concern you?
                            </Form.Label>
                            {[
                                'Reduced physician oversight',
                                'Potential for lower care quality',
                                'Fragmentation of care',
                                'Inconsistent training and education standards',
                                'Increased liability and risk',
                                'Undermining the physician role',
                                'Compromised patient safety',
                                'I do not have concerns about scope expansion',
                                'Other'
                            ].map((option) => (
                                <Form.Check
                                    key={option}
                                    type="checkbox"
                                    label={option}
                                    name="appDrawbacks"
                                    value={option}
                                    checked={formData.appDrawbacks?.includes(option)} // Assuming you create a new field for appDrawbacks
                                    onChange={handleChange}
                                />
                            ))}
                        </Form.Group>

                        {/* Conditionally display the text input for "Other" if selected */}
                        {formData.appDrawbacks?.includes('Other') && (
                            <Form.Group controlId="otherAppDrawbacks" style={{ marginTop: 0, marginBottom: 12 }}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please specify other APP drawbacks"
                                    name="otherAppDrawbacks"
                                    value={formData.otherAppDrawbacks}
                                    onChange={handleChange}
                                    style={{ padding: 4 }}
                                />
                            </Form.Group>
                        )}

                        {/* NEW: Specialties Most Impacted by AI */}
                        <Form.Group controlId="appImpactedSpecialties" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                Which Specialties Do you think will see the most significant changes in workforce composition due to Advanced Practice Provider Scope Expansion?
                            </Form.Label>
                            <Form.Label style={{ marginBottom: 0, fontWeight: '500', fontSize: 'small', }}>
                                These are specialties where you anticipate a shift in the workforce dynamics (for MD graduates or equivalent), as APPs may take on a larger role in providing care traditionally performed by physicians.
                            </Form.Label>

                            {specialtyList.map((specialty) => (
                                <Form.Check
                                    key={specialty}
                                    type="checkbox"
                                    label={specialty}
                                    name="appImpactedSpecialties"
                                    value={specialty}
                                    checked={formData.appImpactedSpecialties.includes(specialty)}
                                    onChange={handleChange}
                                />
                            ))}
                        </Form.Group>

                        {/* Conditionally display the text input for "Other" if selected */}
                        {formData.appImpactedSpecialties?.includes('Other') && (
                            <Form.Group controlId="appOtherImpactedSpecialties" style={{ marginTop: 0, marginBottom: 12 }}>
                                <Form.Control
                                    type="text"
                                    placeholder="Please specify"
                                    name="appOtherImpactedSpecialties"
                                    value={formData.appOtherImpactedSpecialties}
                                    onChange={handleChange}
                                    style={{ padding: 4 }}
                                />
                            </Form.Group>
                        )}

                        {/* CONSIDERING LESS! */}

                        {/* NEW: Reconsidering Specialties Due to AI */}
                        <Form.Group controlId="appLessConsideredSpecialtiesExist" className="mb-3">
                            <DualSegmentedControlInput hideLabel={true}
                                label={"Are There Any Specialties You're Considering LESS Favorably due to the foreseen impact of Scope Expansion?"}
                                value={formData.appLessConsideredSpecialtiesExist} choiceA={"Yes"} choiceB={"No"}
                                onChange={(target) => { setFormData({ ...formData, appLessConsideredSpecialtiesExist: target }) }} />
                        </Form.Group>

                        {formData.appLessConsideredSpecialtiesExist === true &&
                            <>
                                {/* Specialties not being considered */}
                                <Form.Group controlId="appNotConsideredSpecialties" className="mb-3">
                                    <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                        If so, which ones?</Form.Label>
                                    {specialtyList.map((specialty) => (
                                        <Form.Check
                                            key={specialty}
                                            type="checkbox"
                                            label={specialty}
                                            name="appNotConsideredSpecialties"
                                            value={specialty}
                                            checked={formData.appNotConsideredSpecialties.includes(specialty)}
                                            onChange={handleChange}
                                        />
                                    ))}
                                </Form.Group>

                                {/* Conditionally display the text input for "Other" if selected */}
                                {formData.appNotConsideredSpecialties?.includes('Other') && (
                                    <Form.Group controlId="appOtherNotConsideredSpecialties" style={{ marginTop: 0, marginBottom: 12 }}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please specify"
                                            name="appOtherNotConsideredSpecialties"
                                            value={formData.appOtherNotConsideredSpecialties}
                                            onChange={handleChange}
                                            style={{ padding: 4 }}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        }

                        {/* CONSIDERING MORE! */}


                        {/* NEW: Reconsidering Specialties Due to AI */}
                        <Form.Group controlId="appMoreConsideredSpecialtiesExist" className="mb-3">
                            <DualSegmentedControlInput hideLabel={true}
                                label={"Are There Any Specialties You're Considering MORE Favorably Due to the foreseen impact of Scope Expansion?"}
                                value={formData.appMoreConsideredSpecialtiesExist} choiceA={"Yes"} choiceB={"No"}
                                onChange={(target) => { setFormData({ ...formData, appMoreConsideredSpecialtiesExist: target }) }} />
                        </Form.Group>


                        {formData.appMoreConsideredSpecialtiesExist === true &&
                            <>
                                {/* Specialties not being considered */}
                                <Form.Group controlId="appMoreConsideredSpecialties" className="mb-3">
                                    <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                        If so, which ones?</Form.Label>
                                    {specialtyList.map((specialty) => (
                                        <Form.Check
                                            key={specialty}
                                            type="checkbox"
                                            label={specialty}
                                            name="appMoreConsideredSpecialties"
                                            value={specialty}
                                            checked={formData.appMoreConsideredSpecialties.includes(specialty)}
                                            onChange={handleChange}
                                        />
                                    ))}
                                </Form.Group>

                                {/* Conditionally display the text input for "Other" if selected */}
                                {formData.appMoreConsideredSpecialties?.includes('Other') && (
                                    <Form.Group controlId="appOtherMoreConsideredSpecialties" style={{ marginTop: 0, marginBottom: 12 }}>
                                        <Form.Control
                                            type="text"
                                            placeholder="Please specify"
                                            name="appOtherMoreConsideredSpecialties"
                                            value={formData.appOtherMoreConsideredSpecialties}
                                            onChange={handleChange}
                                            style={{ padding: 4 }}
                                        />
                                    </Form.Group>
                                )}
                            </>
                        }

                    </div>


                    <div style={{ backgroundColor: 'white', border: '1px solid rgb(200,200,210)', borderRadius: 5, padding: 16, marginBottom: 8 }}>

                        <div style={{ display: 'flex', gap: 8, marginBottom: -4 }}>
                            <h6 style={{ color: 'white', backgroundColor: 'black', padding: '4px 8px', borderRadius: 5, fontWeight: 'bold', }}>5/5</h6>
                            <h5 style={{ fontWeight: '700' }}>Final Thoughts</h5>
                        </div>
                        <p style={{ fontWeight: '600', marginBottom: 12 }}>Let's get to the bottom of it! Do you perceive either Artifical Intelligence or Advanced Practice Providers as a threat to your future career prospects?</p>


                        <Form.Group controlId="aiScared" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                How Concerned Are You About the future of AI in medicine and its impact on your future career?</Form.Label>
                            <p style={{ fontWeight: '500', marginBottom: 2 }}><i>1 = Not concerned at all; 5 = Very concerned</i></p>
                            <MultipleSegmentedControlInput
                                options={['1', '2', '3', '4', '5']}
                                value={formData.aiScared}
                                onChange={(value) => {
                                    setFormData({ ...formData, aiScared: value });
                                }}
                                hideLabel={true}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please select the option that best aligns with your beliefs.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="appScared" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                How Concerned Are You About trends in Scope Expansion and its impact on your future career?</Form.Label>
                            <p style={{ fontWeight: '500', marginBottom: 2 }}><i>1 = Not concerned at all; 5 = Very concerned</i></p>
                            <MultipleSegmentedControlInput
                                options={['1', '2', '3', '4', '5']}
                                value={formData.appScared}
                                onChange={(value) => {
                                    setFormData({ ...formData, appScared: value });
                                }}
                                hideLabel={true}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please select the option that best aligns with your beliefs.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="biggerThreat" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                Which do you believe is a greater threat to your future career prospects?</Form.Label>
                            {['Artificial Intelligence in Medicine', 'Advanced Practice Provider Scope Expansion', 'Both Equally'].map((option) => (
                                <Form.Check
                                    required
                                    key={option}
                                    type="radio"
                                    label={option}
                                    name="biggerThreat"
                                    value={option}
                                    checked={formData.biggerThreat === option}
                                    onChange={handleChange}
                                />
                            ))}
                            <Form.Control.Feedback type="invalid">
                                Please select the option that best aligns with your beliefs.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="aiPreparedness" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                DO YOU FEEL MEDICAL SCHOOL PREPARES YOU WELL FOR WORKING ALONGSIDE ARTIFICAL INTELLIGENCE IN MEDICINE?</Form.Label>
                            <p style={{ fontWeight: '500', marginBottom: 2 }}><i>1 = No, not at all; 5 = Yes, very well</i></p>
                            <MultipleSegmentedControlInput
                                options={['1', '2', '3', '4', '5']}
                                value={formData.aiPreparedness}
                                onChange={(value) => {
                                    setFormData({ ...formData, aiPreparedness: value });
                                }}
                                hideLabel={true}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please select your level of preparedness.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="appPreparedness" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                DO YOU FEEL MEDICAL SCHOOL PREPARES YOU WELL FOR WORKING ALONGSIDE ADVANCED PRACTICE PROVIDERS?</Form.Label>
                            <p style={{ fontWeight: '500', marginBottom: 2 }}><i>1 = No, not at all; 5 = Yes, very well</i></p>
                            <MultipleSegmentedControlInput
                                options={['1', '2', '3', '4', '5']}
                                value={formData.appPreparedness}
                                onChange={(value) => {
                                    setFormData({ ...formData, appPreparedness: value });
                                }}
                                hideLabel={true}
                            />
                            <Form.Control.Feedback type="invalid">
                                Please select your level of preparedness.
                            </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group controlId="studentPreparation" className="mb-3">
                            <Form.Label style={{ marginBottom: 0, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase' }}>
                                What are some Measures you believe medical schools can take to Prepare Students for the future concerning AI and Scope Expansion?</Form.Label>
                            <Form.Control
                                as="textarea"
                                rows={3}
                                name="studentPreparation"
                                value={formData.studentPreparation}
                                onChange={handleChange}
                            />
                        </Form.Group>

                        <Button variant="primary" type="submit"
                            onClick={() => {
                                //submitAnswers()
                            }}
                        >
                            Submit and View Results
                        </Button>

                        <br />
                        <br />
                        <p style={{ marginBottom: 4 }}>By submitting your answers, you agree to our <span style={{ textDecoration: 'underline' }}
                            onClick={() => { navigate('/survey-privacy-policy') }}>Survey Privacy Policy</span> which can be viewed at https://www.thebrightdoctor.com/survey-privacy-policy</p>
                        <p style={{ marginBottom: 4 }}>You may contact us at info@brightstudent.org for any questions or technical difficulties.</p>
                    </div>

                </Form>



            </Container >
            <Footer />
        </div >
    );
};

export default Survey;

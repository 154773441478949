import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavBar from '../../NavBar/NavBar';
import SiteStyles from '../../Site.module.css'
import TextStyles from '../../styles/text.module.css'
import DivStyles from '../../styles/divs.module.css'
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import Styles from './MPHome.module.css'
import axios from 'axios';
import Config from '../../../config.json'
import ApproachComparison from './ApproachComparison'

import { ArrowRightIcon, ClockIcon, CorporateIcon, MoneyIcon, PersonIcon, SearchIcon } from '../../Icons'
import MPPanel from '../MPPanel/MPPanel';
import MPMarketplace from './MPMarketplace';

const MPHome = (props) => {
    let navigate = useNavigate();

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1000);

    useEffect(() => {
        // Check mobile size for conditional render 
        const handleResize = () => { setIsMobile(window.innerWidth <= 850); };
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    const scrollToConsultants = () => { document.getElementById('marketplace-grid')?.scrollIntoView({ behavior: 'smooth' }); }

    const urlChangeEvent = new Event('urlChange');

    const CategoryCard = ({ title, tag, description, colorDark, colorMid, colorLight, imageSource, minPrice }) => {
        return (
            <div className={Styles.CardContainer} onClick={() => {
                const queryParams = new URLSearchParams(window.location.search);
                queryParams.set('tag', tag);
                queryParams.delete('subtag');
                queryParams.delete('search');
                queryParams.set('page', '1');
                window.history.pushState(null, '', `?${queryParams.toString()}`);

                // Dispatch the custom event
                window.dispatchEvent(urlChangeEvent);

                scrollToConsultants();
            }}>
                <div className={Styles.CategoryCard} style={{ background: `linear-gradient(45deg, white 50%, ${colorLight}33 90%, ${colorLight}66 100%)`, borderColor: colorDark, }} >

                    {/* Card Image - Position absolute */}
                    <img style={{ position: 'absolute', objectFit: 'contain', right: 16, bottom: 0, height: 110, }} src={imageSource} />

                    <div className={Styles.CardHeader}>
                        <p className={TextStyles.BoldLarge} style={{ color: colorDark, fontWeight: 700 }}>{title}</p>

                        <div style={{
                            borderRadius: 20, display: 'flex', justifyContent: 'center', alignItems: 'center',
                            backgroundColor: colorDark, minWidth: 24, minHeight: 24
                        }}>
                            <ArrowRightIcon color={'white'} strokeWidth={3} width={16} height={16} />
                        </div>
                    </div>

                    {/* Text */}
                    <div style={{ padding: '0px 110px 12px 12px' }}>
                        {/* Description Text */}
                        <p className={TextStyles.Body} style={{ color: colorDark, fontSize: '11pt' }}>{description}</p>

                        {/* Min Price */}
                        <p className={TextStyles.Body} style={{
                            color: colorDark, fontWeight: 'bold', fontSize: 'small', textTransform: 'uppercase', marginTop: 12
                        }}>From {minPrice}/Hr</p>

                        {/* Availability */}
                        <p className={TextStyles.Body} style={{
                            color: colorMid, fontWeight: '600', fontSize: 'small',
                        }}><ClockIcon color={colorMid} strokeWidth={2.5} width={14} height={14} mb={3} /> Available within 24h</p>
                    </div>

                </div>
            </div>
        );
    };



    return (

        <div style={{ backgroundColor: 'white' }}>
            <NavBar />

            {/* JUMBOTRON */}
            <div className={Styles.JumbotronBackground} style={{ position: 'relative' }}>
                <Container>
                    <div className={Styles.Jumbotron}>


                        {/* Title */}
                        {/* <h1 className={Styles.Banner}>From Applications to Success</h1> */}
                        <h1 className={Styles.Banner} style={{ marginTop: 32 }}>From Applications to <b style={{ color: '#008067' }}>Success</b></h1>
                        <h1 className={Styles.Banner}>Top Students Guiding Your Way</h1>

                        <p className={TextStyles.Body} style={{ fontWeight: '600', textAlign: 'center', margin: '32px 0px 8px 0px' }}>POPULAR</p>
                        {/* Buttons */}
                        <div style={{ display: 'flex', justifyContent: 'center', margin: '4px 0px 24px 0px', gap: 12, flexWrap: 'wrap' }}>
                            <CategoryCard
                                title="College"
                                description="Applications, Essay Edits, Interview Practice, SAT/ACT prep"
                                colorDark={'#003E7D'}  // Deeper blue
                                colorMid={'#0057b0'}
                                colorLight={'#007dfb'}  // Brighter blue
                                tag={'college'}
                                imageSource={`https://storage.googleapis.com/bright-doctor-image-storage/subject-1.png`}
                                minPrice={`$30`}
                            />

                            <CategoryCard
                                title="Medicine"
                                description="Personal Statement, Interviews, Secondaries, MCAT®, CASPer®"
                                colorDark={'#4a148c'}  // Deep purple
                                colorMid={'#9c27b0'}  // Bright purple
                                colorLight={'#9c27b0'}  // Bright purple
                                tag={'medicine'}
                                imageSource={`https://storage.googleapis.com/bright-doctor-image-storage/subject-3.png`}
                                minPrice={`$35`}
                            />
                            {/* 
                            <CategoryCard
                                title="Graduate"
                                description="Personal Statement, Interviews, Research Portfolio, GRE®, GMAT®"
                                colorDark={'#9B2B11'}  // Deep Red
                                colorMid={'#e84118'}  // Bright Red
                                colorLight={'#EE7A5D'}  // Light red
                                tag={'graduate'}
                                imageSource={`https://storage.googleapis.com/bright-doctor-image-storage/subject-3.png`}
                                minPrice={`$35`}
                            /> */}
                        </div>

                        <div id="university-gallery">
                            <div style={{ width: '100%', padding: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
                                <div style={{ display: 'flex', color: '#404040', marginBottom: 6 }}>
                                    <p className={TextStyles.Body}>Used by students at </p>
                                    <small style={{ marginBottom: 8 }}>†</small>
                                </div>
                                <div style={{ display: 'flex', gap: 16, opacity: 0.8, flexWrap: 'wrap', justifyContent: 'center' }}>
                                    <img src={`${Config.IMAGES_BASE_URL}/unis/ucla.jpg`} style={{ height: 34 }} />
                                    <img src={`${Config.IMAGES_BASE_URL}/unis/uoft.png`} style={{ height: 34 }} />
                                    <img src={`${Config.IMAGES_BASE_URL}/unis/harvard.png`} style={{ height: 34 }} />
                                    <img src={`${Config.IMAGES_BASE_URL}/unis/stanford.png`} style={{ height: 34 }} />
                                    <img src={`${Config.IMAGES_BASE_URL}/unis/utexas.png`} style={{ height: 34 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
            </div>

            <div id="top-marketplace" />

            <MPMarketplace scrollToTop={() => {
                document.getElementById('top-marketplace')?.scrollIntoView({ behavior: 'smooth' });
            }} />

            <ApproachComparison />


            {/* BLACK DIV */}
            <div style={{ background: 'black', padding: 24, zIndex: 100 }}>
                {/* Final CTA */}
                <div style={{ textAlign: 'center', fontFamily: 'Source Sans Pro' }}>
                    <p className={TextStyles.BoldLarge} style={{ color: 'white', fontSize: 'x-large', textAlign: 'center', margin: '32px 0px 8px 0px', }}>Ready To Excel?</p>
                    {<p style={{ marginBottom: 8, color: 'white' }}>Find your perfect peer mentor today</p>}

                    <button className={SiteStyles.ActionButtonSlim}
                        style={{ backgroundColor: 'white', color: 'black', marginTop: 16, padding: '4px 16px' }} onClick={() => { navigate('/register') }}>Access →</button>
                </div>

                <br />

            </div>

            {/* Footer */}
            <Footer white={true} />
        </div >
    );
}

const mapStateToProps = state => {
    return {
        URLRef: state.URLRef,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIsAuthenticated: (val) => dispatch({ type: "SET_AUTHENTICATION", payload: { value: val } }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MPHome);
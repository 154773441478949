import React, { Suspense, lazy } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Eager imports for essential components
import Home from './components/Home/Home';
import Register from './components/Register/Register';
import Login from './components/Login/Login';
import TOS from './components/TOS_PP/TOS';
import Privacy from './components/TOS_PP/Privacy';
import Contact from './components/Contact/Contact';
import FavoriteSchools from './components/SchoolDirectory/FavoriteSchools';
import AddQEssay from './components/Course/AddQEssay';
import AnswerData from './components/Course/AnswerData';
import MPHome from './components/Marketplace/MPHome/MPHome';
import MPConsultant from './components/Marketplace/MPConsultant/MPConsultant';
import Survey from './components/Survey/Survey';
import SurveyThankYou from './components/Survey/Survey-Thank-You';
import SurveyPP from './components/Survey/Survey-PP';
import ConsultantApplications from './components/Course/ConsultantApplications/ConsultantApplications';

// Lazy imports for less immediately necessary components
const WebApp = lazy(() => import('./components/App/WebApp'));
const SchoolDirectory = lazy(() => import('./components/SchoolDirectory/SchoolDirectory'));
const Blog = lazy(() => import('./components/Blog/Blog'));
const UnsubscribeEmail = lazy(() => import('./components/Unsubscribe/ Unsubscribe'));
const Reset = lazy(() => import('./components/Reset/Reset'));

// Product Pages
const ProductMedInterview = lazy(() => import('./components/ProductPages/MedInterview/MedInterview'));
const ProductCasper = lazy(() => import('./components/ProductPages/Casper/Casper'));
const ProductAAMCpreview = lazy(() => import('./components/ProductPages/AAMCpreview/AAMCpreview'));

// Admin components
const CourseMain = lazy(() => import('./components/Course/CourseMain'));
const CourseCreator = lazy(() => import('./components/Course/CourseCreator/CourseCreator'));
const Questions = lazy(() => import('./components/Course/Questions'));
const AddQInterview = lazy(() => import('./components/Course/AddQInterview'));
const AddQCasper = lazy(() => import('./components/Course/AddQCasper'));
const AddQMultipleChoice = lazy(() => import('./components/Course/AddQMultipleChoice'));
const AddQAAMCPreview = lazy(() => import('./components/Course/AddQAAMCPreview'));
const SiteUsers = lazy(() => import('./components/Course/SiteUsers/SiteUsers'));
const AdminPrivacyPolicy = lazy(() => import('./components/Course/PrivacyPolicy'));
const AdminTermsOfService = lazy(() => import('./components/Course/TermsOfService'));
const ViewSchools = lazy(() => import('./components/Course/ViewSchools'));
const AddSchool = lazy(() => import('./components/Course/AddSchool'));
const GenerateSchoolHTML = lazy(() => import('./components/Course/GenerateSchoolHTML'));
const UserEvents = lazy(() => import('./components/Course/UserEvents'));
const AddArticle = lazy(() => import('./components/Course/AddArticle'));
const Articles = lazy(() => import('./components/Course/Articles'));
const ImageUpload = lazy(() => import('./components/Course/ImageUpload'));
const GenerateArticleHTML = lazy(() => import('./components/Course/GenerateArticleHTML'));
const Tags = lazy(() => import('./components/Course/Tags/Tags'));
const AdminLog = lazy(() => import('./components/Course/AdminLog'));

const App = (props) => {
  const isAdmissionPeer = window.location.hostname === 'admissionpeer.com' || window.location.hostname === 'www.admissionpeer.com';

  return (
    < BrowserRouter >
      <Suspense fallback={<div>Loading...</div>}> {/* Fallback component displayed during loading */}
        <Routes>
          {/* Non-admin routes */}
          <Route path="/" exact element={isAdmissionPeer ? <MPHome /> : <Home />} />
          <Route path='/register/' element={<Register />} />
          <Route path='/login/' element={<Login />} />
          <Route path='/reset/' element={<Reset />} />
          <Route path='/tos/' element={<TOS />} />
          <Route path='/privacy/' element={<Privacy />} />
          <Route path='/contact/' element={<Contact />} />

          <Route path='/blog' element={<Blog />} />
          <Route path='/emailstatus' element={<UnsubscribeEmail />} />
          <Route path='/interview' element={<SchoolDirectory />} />
          <Route path='/mstar' element={<SchoolDirectory />} />
          <Route path='/favorite-schools' element={<FavoriteSchools />} />

          <Route path='/marketplace' element={<MPHome />} />
          <Route path='/consultant/:cid' element={<MPConsultant />} />

          <Route path='/survey' element={<Survey />} />
          <Route path='/survey-thank-you' element={<SurveyThankYou />} />
          <Route path='/survey-privacy-policy' element={<SurveyPP />} />

          {/* Admin routes */}
          <Route path='/course' element={<CourseMain />} />
          <Route path='/course/add/' element={<CourseCreator />} />
          <Route path='/course/questions/' element={<Questions />} />
          <Route path='/course/qinterview/' element={<AddQInterview />} />
          <Route path='/course/qcasper/' element={<AddQCasper />} />
          <Route path='/course/qmultiplechoice/' element={<AddQMultipleChoice />} />
          <Route path='/course/qaamcpreview/' element={<AddQAAMCPreview />} />
          <Route path='/course/qessay/' element={<AddQEssay />} />
          <Route path='/course/users/' element={<SiteUsers />} />
          <Route path='/course/updateprivacypolicy/' element={<AdminPrivacyPolicy />} />
          <Route path='/course/updatetermsofservice/' element={<AdminTermsOfService />} />
          <Route path='/course/adminlog/' element={<AdminLog />} />
          <Route path='/course/answerdata/' element={<AnswerData />} />
          <Route path='/course/consultantapplications/' element={<ConsultantApplications />} />

          <Route path='/course/uploadimage/' element={<ImageUpload />} />

          <Route path='/course/events/' element={<UserEvents />} />
          <Route path='/course/tags/' element={<Tags />} />

          <Route path='/course/schools/' element={<ViewSchools />} />
          <Route path='/course/schools/add' element={<AddSchool />} />
          <Route path='/course/schools/generate-html' element={<GenerateSchoolHTML />} />

          <Route path='/course/articles/' element={<Articles />} />
          <Route path='/course/articles/add' element={<AddArticle />} />
          <Route path='/course/articles/generate-html' element={<GenerateArticleHTML />} />

          {/* Product pages */}
          <Route path='/products/medinterview/' element={<ProductMedInterview />} />
          <Route path='/products/casper/' element={<ProductCasper />} />
          <Route path='/products/aamcpreview/' element={<ProductAAMCpreview />} />

        </Routes>
      </Suspense>

      {/* These routes take up the full screen width */}
      <Routes>
        <Route path="/app/*" element={<WebApp />} />
      </Routes>
    </BrowserRouter >
  );
}

export default (App);
import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link, useLocation, useParams, useNavigate } from 'react-router-dom';
import NavBar from '../../NavBar/NavBar';
import SiteStyles from '../../Site.module.css'
import TextStyles from '../../styles/text.module.css'
import DivStyles from '../../styles/divs.module.css'
import ButtonStyles from '../../styles/buttons.module.css'
import { Container } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import Styles from './MPConsultant.module.css'
import axios from 'axios';
import getCountryEmoji from '../getCountryEmoji'
import Config from '../../../config.json'

import { ArrowRightIcon, ChatIcon, ClockIcon, CorporateIcon, MoneyIcon, PersonIcon, SearchIcon } from '../../Icons'
import MPPanel from '../MPPanel/MPPanel';

const MPConsultantDetailedView = (props) => {
    let navigate = useNavigate();

    const { cid } = useParams(); // 'cid' will be the consultant's ID from the URL
    const [consultant, setConsultant] = useState(null);
    const [voucher, setVoucher] = useState(null);

    const [isLoggedIn, setIsLoggedIn] = useState(false);

    const checkLoggedIn = () => {
        axios.get(`${Config.API_BASE_URL}/auth/check-headers`).then(res => {
            if (res.data.hasAuthHeaders) {
                setIsLoggedIn(true);
            }
        }).catch(err => { console.log(err); })
    }

    const fetchConsultant = () => {
        axios.get(`${Config.API_BASE_URL}/public/consultant/${cid}`).then(res => {
            setConsultant(res.data.data);
            console.log(res.data);
            if (res.data.voucher) { setVoucher(res.data.voucher); }
        }).catch(err => { console.log('Error has occurred when fetching consultant') })
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 850);
    const [isTablet, setIsTablet] = useState(window.innerWidth <= 1000);

    useEffect(() => {
        fetchConsultant();
        checkLoggedIn();

        // Check mobile size for conditional render 
        const handleResize = () => { setIsMobile(window.innerWidth <= 850); };
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    const messageConsultant = () => {
        axios.post(`${Config.API_BASE_URL}/messages/create/${cid}`).then(res => {
            navigate('/app/messages/', { state: { cid: cid } });
        }).catch(err => { console.log('Error has occurred when fetching consultant') })
    }

    const choosePackage = (p) => {
        const packageId = p._id;
        const consultantId = cid;

        if (isLoggedIn) {
            console.log('Stripe API Call')
            axios.post(`${Config.API_BASE_URL}/paymentv2/create-checkout-session-package/`, { consultantId, packageId }).then(res => {
                console.log("i did it")
                window.location.href = res.data.url;
            }).catch(err => { console.log('Error has occurred when fetching consultant') })
        } else {
            navigate('/register')
        }
    }

    return (
        <div>
            <p className={TextStyles.Bold} style={{ fontSize: 'large', }}>MARKETPLACE | <span style={{ fontWeight: 500 }}>CONSULTANT PROFILE</span></p>
            {
                consultant === null ?
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%', }}>
                        <div className={SiteStyles.Spinner} />
                    </div>
                    :
                    <div style={{ padding: '16px 0px' }}>

                        <div className={Styles.TopRow}>
                            {/* First, profile picture */}
                            <img
                                src={`${Config.IMAGES_BASE_URL}/profilepics/${consultant.profilePicture}`}
                                className={Styles.ProfileImage}
                                style={{ width: 200, height: 200, objectFit: 'cover', minWidth: 200 }}
                            />

                            {/* Information */}
                            <div className={Styles.ConsultantInfo}>
                                <div className={Styles.StatusBadges}>
                                    <span className={Styles.CountryBadge}>
                                        {consultant.consultantCountry}
                                        <div style={{ marginLeft: 6 }}>
                                            {getCountryEmoji(consultant.consultantCountry)}
                                        </div>
                                    </span>
                                    <span className={Styles.LastActiveBadge}>
                                        <ClockIcon width={16} height={16} mr={8} />
                                        Last active: {consultant.lastActive}
                                    </span>
                                </div>


                                <p className={TextStyles.Bold} style={{ fontSize: 'xxx-large', marginBottom: -8, marginTop: -8 }}>{consultant.name}</p>
                                <p className={TextStyles.Bold} style={{ fontWeight: 600, fontSize: 'large' }}>{consultant.taglineOne}</p>
                                <p className={TextStyles.Body} style={{ fontSize: 'large', marginBottom: 8 }} >{consultant.taglineTwo}</p>

                                <button className={ButtonStyles.StandardDark}
                                    onClick={() => { messageConsultant() }}>
                                    Message
                                    <div style={{ display: 'inline-block', marginLeft: 8 }}>
                                        <ChatIcon color="white" />
                                    </div>
                                </button>

                            </div>
                        </div>

                        {/* About Me */}
                        <div className={Styles.SectionCard} style={{ marginBottom: '24px' }}>  {/* Added marginBottom */}
                            <h2 className={Styles.SectionTitle}>ABOUT ME</h2>
                            {consultant.aboutMe.split('\n').map(x => {
                                return (<p className={TextStyles.Body} style={{ fontSize: 'large', marginBottom: 8 }} >{x}</p>)
                            })}
                        </div>

                        {/* Two Column Section Container */}
                        <div style={{
                            display: 'flex',
                            flexWrap: isMobile ? 'wrap' : 'nowrap',
                            gap: '24px',  // Increased from 16px
                            marginBottom: '24px'
                        }}>
                            {/* Packages */}
                            <div className={Styles.SectionCard} style={{ width: isMobile ? '100%' : '50%' }}>
                                <h2 className={Styles.SectionTitle}>PACKAGES</h2>
                                {voucher && (
                                    <div className={Styles.VoucherCard}>
                                        <p className={TextStyles.Bold} style={{ fontSize: '0.875rem', color: '#044720' }}>MARKETPLACE VOUCHER</p>
                                        <p className={TextStyles.Bold} style={{ color: '#044720' }}>{voucher.title}</p>
                                        <p className={TextStyles.Body} style={{ color: '#044720', fontSize: 'small' }}>
                                            This voucher will be automatically applied during checkout for any package with a minimum value of ${voucher.minOrderValue / 100}. Simply choose an eligible package below.
                                        </p>
                                    </div>
                                )}
                                {consultant.packages.map(x => {
                                    return (
                                        <div
                                            className={Styles.Package}
                                            onClick={() => { choosePackage(x) }}
                                        >
                                            <div className={Styles.PackageContent}>
                                                <div className={Styles.PackageMeta}>
                                                    <span className={Styles.PackageTag} >
                                                        ${x.price}
                                                    </span>
                                                    <span className={Styles.PackageTag}>
                                                        {x.time} minutes
                                                    </span>
                                                    {x.live && (
                                                        <span className={Styles.PackageTag}>
                                                            Live
                                                        </span>
                                                    )}
                                                </div>
                                                <p className={TextStyles.Bold} style={{
                                                    fontSize: '1.25rem',
                                                    marginBottom: '0.5rem'
                                                }}>
                                                    {x.title}
                                                </p>
                                                <p className={TextStyles.Body} style={{
                                                    fontSize: '1rem',
                                                    color: '#4B5563'
                                                }}>
                                                    {x.description}
                                                </p>
                                            </div>
                                            <div style={{
                                                minWidth: 24,
                                                color: '#2563eb',
                                                transition: 'transform 0.2s'
                                            }}>
                                                <ArrowRightIcon />
                                            </div>
                                        </div>
                                    )
                                })}

                                <p style={{ marginTop: 16 }} className={TextStyles.Body}>Make sure you communicate with {consultant.name} before purchasing any packages.</p>
                                <button className={ButtonStyles.ModernButton} style={{ paddingLeft: 0, marginLeft: 0 }} onClick={() => { messageConsultant() }}>
                                    Send {consultant.name} a Message →</button>
                            </div>

                            <div className={Styles.SectionCard} style={{ width: isMobile ? '100%' : '50%' }}>
                                {/* Education — Only display if they've been entered */}
                                {consultant.education && consultant.education.length > 0 &&
                                    <>
                                        <p className={TextStyles.Bold} style={{ fontWeight: 600, fontSize: 'large', textAlign: 'center', marginBottom: 16 }}>EDUCATION & EXPERIENCE</p>

                                        <div style={{ display: 'flex', gap: 16 }}>
                                            <div className={Styles.TimelineContainer}>
                                                {consultant.education.map(x => (
                                                    <div className={Styles.TimelineItem} key={x.title}>
                                                        <div className={Styles.TimelineDate}>
                                                            {x.startYear} - {x.endYear}
                                                        </div>
                                                        <div className={Styles.TimelineContent}>
                                                            <p className={TextStyles.Body}>{x.title}</p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <br />
                                    </>
                                }


                                {/* Test Scores — Only display if they've been entered */}
                                {consultant.testScores && consultant.testScores.length > 0 &&
                                    <>
                                        <p className={TextStyles.Bold} style={{ fontWeight: 600, fontSize: 'large', textAlign: 'center', marginBottom: 16 }}>TEST SCORES</p>
                                        <div style={{ display: 'flex', gap: 16 }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {consultant.testScores.map(x => {
                                                    return (<p className={TextStyles.Bold} style={{ fontSize: 'large', marginBottom: 8 }} >{x.title}</p>)
                                                })}
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                {consultant.testScores.map(x => {
                                                    return (<p className={TextStyles.Body} style={{ fontSize: 'large', marginBottom: 8 }} >{x.score}</p>)
                                                })}
                                            </div>
                                        </div>
                                    </>
                                }

                            </div>

                            {/* Footer */}
                        </div>
                    </div>
            }
        </div >
    );
}

const mapStateToProps = state => {
    return {
        URLRef: state.URLRef,
    }
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIsAuthenticated: (val) => dispatch({ type: "SET_AUTHENTICATION", payload: { value: val } }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MPConsultantDetailedView);
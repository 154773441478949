import React, { useState, useEffect } from 'react';
import { Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom';
import SuccessItem from '../App/subcomponents/SuccessItem';
import Config from '../../config.json'
import { jwtDecode } from 'jwt-decode'
import { isMobile } from 'react-device-detect';

import Styles from '../Site.module.css'
import TextStyles from '../TextStyles.module.css'
import ErrorItem from '../App/subcomponents/ErrorItem';

const Login = ({ setIsAuthenticated }) => {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")

    const [error, setError] = useState(null);

    let navigate = useNavigate();
    let location = useLocation();

    const [forgotPassword, setForgotPassword] = useState(false);
    const [resetMessage, setResetMessage] = useState(null);

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token) {
            const decodedToken = jwtDecode(token);
            console.log(decodedToken)
            const currentDate = new Date();

            // Check if the token is expired
            if (decodedToken.exp * 1000 > currentDate.getTime()) {
                setIsAuthenticated(true);

                // Check if phantom account
                // I.e., don't auto log-in for phantom accounts
                axios.get(`${Config.API_BASE_URL}/auth/check-headers`).then(res => {
                    if (res.data.hasAuthHeaders) {
                        const accountStatus = res.data.role;
                        if (accountStatus !== 'phantom') {
                            navigate('/app/');
                        }
                    }
                }).catch(err => { console.log(err); })
            }
        }
    }, [navigate]);


    const submitForm = (e) => {
        e.preventDefault(); // So the page doesn't refresh

        if (forgotPassword) {
            requestPasswordReset();
        } else {
            axios.post(`${Config.API_BASE_URL}/auth/login`, { email: email, password: password }).then(res => {
                // Set Auth Headers
                localStorage.setItem('authToken', res.data.token);
                axios.defaults.headers.common['Authorization'] = `Bearer ${res.data.token}`

                setIsAuthenticated(true);

                navigate('/app/')
            }).catch(err => {
                if (err.response.data.error === 'Invalid credentials') {
                    setError('Invalid credentials')
                } else {
                    setError(err)
                }
                console.log(err);
            })
        }
    }

    const requestPasswordReset = () => {
        axios.post(`${Config.API_BASE_URL}/auth/request-reset-password`, { email: email }).then(res => {
            setResetMessage(res.data.message)
            console.log(res.data);
        }).catch(err => {
            setResetMessage("There has been an error. Please email us for further support.")
        })
    }

    return (
        <div>
            <div className={Styles.ImageCenteredBG} >
                <div style={{ backgroundColor: '#ffffff', borderRadius: 1000, }}>
                    <img src={`${Config.IMAGES_BASE_URL}/stickers/6.png`}
                        style={{
                            width: '32vw', height: '32vw',
                            margin: -24
                        }}
                        className={Styles.tinkeringImage}
                    />
                </div>
            </div>

            <div className={Styles.LeftFloatingPane}>

                {location.state && location.state.successRegistration &&
                    <div style={{ margin: '12px 0px' }}>{SuccessItem("Registration complete")}</div>}

                {location.state && location.state.message &&
                    <div style={{ margin: '12px 0px' }}>{ErrorItem(location.state.message)}</div>}

                <p className={TextStyles.Title}>
                    {forgotPassword ? "Reset Password" : "Sign in"}
                </p>
                <br />
                <Form onSubmit={submitForm}>
                    {/* EMAIL */}
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className={TextStyles.Body}>Email address</Form.Label>
                        <Form.Control className={TextStyles.Body} type="email" placeholder="Enter email" value={email} onChange={e => setEmail(e.target.value)} />
                    </Form.Group>

                    {/* PASSWORD */}
                    {
                        !forgotPassword && <Form.Group className="mb-3" controlId="formBasicPassword">
                            <Form.Label className={TextStyles.Body}>Password</Form.Label>
                            <Form.Control className={TextStyles.Body} type="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} />
                        </Form.Group>
                    }

                    {/* SUBMIT BUTTON */}
                    <button className={Styles.ActionButtonSlim} type="submit" >
                        {forgotPassword ? "Send Link To Reset Password" : "Submit"}
                    </button>
                </Form>

                {resetMessage && <div style={{ marginTop: 8 }}>
                    <p className={TextStyles.H6}>STATUS</p>
                    {resetMessage.split('\n').map(x => {
                        return <p className={TextStyles.Body}>{x}</p>
                    })}
                </div>}

                <button className={Styles.ModernButton} onClick={() => { setError(null); setForgotPassword(x => !x) }}>
                    {forgotPassword ? "Back" : "Forgot Password"}
                </button>

                {error !== null ? ErrorItem(error.toString()) : null}
                <br />

                {isMobile && <div style={{ display: 'flex', backgroundColor: 'rgb(247,247,247)', padding: '6px', borderRadius: '5px' }}>
                    <img src={`${Config.ICONS_BASE_URL}/color/laptop-screen.png`} height={32} style={{ marginRight: 12 }} />
                    <p className={TextStyles.Body} style={{ marginBottom: 0 }}>Sign-in on a <b>computer</b> for the best experience</p>
                </div>}

                <br />
                <p className={TextStyles.Body}>Need support? Email info@brightstudent.org</p>
            </div >
        </div >
    );
}

const mapStateToProps = state => {
    return {
        // successRegistration: state.successRegistration,
    }
};


const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        setIsAuthenticated: (val) => dispatch({ type: "SET_AUTHENTICATION", payload: { value: val } }),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);
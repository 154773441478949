import TextStyles from '../TextStyles.module.css';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Footer from '../Footer/Footer';
import Config from '../../config.json';
import { useLocation } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SurveyThankYou = () => {
    const location = useLocation();

    const [specialtyCounts, setSpecialtyCounts] = useState({
        notConsideredSpecialties: [],
        moreConsideredSpecialties: [],
        appNotConsideredSpecialties: [],
        appMoreConsideredSpecialties: []
    });

    useEffect(() => {
        document.getElementById('top')?.scrollIntoView({ behavior: 'smooth' });
    }, [location]);

    const [didntCompleteForm, setDidntCompleteForm] = useState(false);

    // Fetch the specialties count data from the backend
    const fetchData = () => {
        axios.get(`${Config.API_BASE_URL}/public/specialties-counts`)
            .then(res => {
                setSpecialtyCounts(res.data.data); // Set the data from the API response
            })
            .catch((err) => {
                console.log(err);
            });
    };

    useEffect(() => {
        if (location && location.state && location.state.completedForm && location.state.completedForm === true) {
            fetchData();
        } else { setDidntCompleteForm(true) }
    }, []);

    // Helper function to transform data for the bar chart
    const transformData = (dataArray) => {
        return {
            labels: dataArray.map(item => item._id),  // Use the specialty name as the label
            datasets: [
                {
                    label: 'Count',
                    data: dataArray.map(item => item.count),  // Use the count as the data
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                }
            ]
        };
    };

    const options = {
        indexAxis: 'y',  // Horizontal bar chart
        scales: {
            x: {
                beginAtZero: true
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Specialties Count'
            }
        }
    };

    return (
        <div style={{
            padding: '32px 8px',
            background: 'linear-gradient(135deg, rgba(245, 245, 255, 1) 0%, rgba(255, 228, 240, 1) 25%, rgba(255, 255, 225, 1) 50%, rgba(225, 240, 255, 1) 75%, rgba(240, 255, 245, 1) 100%)',
            fontFamily: 'Source Sans Pro',
        }}>
            <Container>
                <div style={{ marginTop: -16, marginBottom: 16, }} id='top' />
                <div className="text-center">
                    <p style={{ textTransform: 'uppercase', marginBottom: 0, fontWeight: '700' }}>The New Debate</p>
                    <h1 className={TextStyles.Title}>AI vs. Scope Expansion</h1>
                    <p className={TextStyles.Body} style={{ color: 'black' }}>
                        Understanding a Generation of Future Physicians
                    </p>
                </div>

                <div style={{ backgroundColor: 'white', border: '1px solid rgb(200,200,210)', borderRadius: 5, padding: 16, marginBottom: 8 }}>
                    <h5>{didntCompleteForm ? 'Please Complete the Form' : 'Thank You!'}</h5>


                    {didntCompleteForm ?
                        <>
                            <p>Please complete the form by clicking <a href="/survey">here</a> to view key results</p>
                        </>
                        :
                        <>
                            <p>Please view the interim results below. We will be emailing everyone the results to the full survey once it's complete.</p>
                            <hr />

                            {/* Horizontal Bar Graph for Not Considered Specialties */}
                            <h6>Specialties viewed less favorably due to foreseen impact of AI in medicine</h6>
                            <Bar data={transformData(specialtyCounts.notConsideredSpecialties)} options={options} />

                            <hr />
                            {/* Horizontal Bar Graph for More Considered Specialties */}
                            <h6>Specialties viewed more favorably due to foreseen impact of AI in medicine</h6>
                            <Bar data={transformData(specialtyCounts.moreConsideredSpecialties)} options={options} />

                            <hr />
                            {/* Horizontal Bar Graph for App Not Considered Specialties */}
                            <h6>Specialties viewed less favorably due to foreseen impact of scope expansion</h6>
                            <Bar data={transformData(specialtyCounts.appNotConsideredSpecialties)} options={options} />

                            <hr />
                            {/* Horizontal Bar Graph for App More Considered Specialties */}
                            <h6>Specialties viewed more favorably due to foreseen impact of scope expansion</h6>
                            <Bar data={transformData(specialtyCounts.appMoreConsideredSpecialties)} options={options} />
                        </>
                    }


                </div>
            </Container>

            <Footer />
        </div>
    );
};

export default SurveyThankYou;

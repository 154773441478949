import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import NavBar from '../../NavBar/NavBar';
import SiteStyles from '../../Site.module.css'
import TextStyles from '../../styles/text.module.css'
import DivStyles from '../../styles/divs.module.css'
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import Footer from '../../Footer/Footer';
import Styles from './MPHome.module.css'
import axios from 'axios';
import Config from '../../../config.json'
import { ArrowRightIcon, ClockIcon, CorporateIcon, MoneyIcon, PersonIcon, SearchIcon } from '../../Icons'


const ApproachComparison = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


    useEffect(() => {
        // Check mobile size for conditional render 
        const handleResize = () => { setIsMobile(window.innerWidth <= 768); };
        window.addEventListener('resize', handleResize);
        return () => { window.removeEventListener('resize', handleResize); };
    }, []);

    return (
        <div style={{ width: '100%', backgroundColor: 'black', padding: '24px 0px' }}>
            <Container>
                {/* Title Section */}
                <div style={{
                    textAlign: 'center',
                    marginBottom: 24,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 8
                }}>
                    <p className={TextStyles.BoldWhite} style={{ fontSize: 'x-large', backgroundColor: 'transparent' }}>Simple & Direct</p>
                    <p className={TextStyles.BodyWhite} style={{ marginTop: -8 }}> Skip the middleman and connect directly with your mentor</p>
                </div>

                <div style={{ width: '100%', justifyContent: 'space-between', gap: 32, display: 'flex', flexWrap: 'wrap' }}>
                    {/* Our Approach Panel */}
                    <div style={{
                        width: isMobile ? '100%' : '48%',
                        background: 'linear-gradient(145deg, rgba(255,255,255,0.1), rgba(255,255,255,0.05))',
                        borderRadius: 16,
                        padding: 12,
                        border: '1px solid rgba(255,255,255,0.1)'
                    }}>
                        <div style={{
                            marginBottom: 16,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 12
                        }}>
                            <div style={{
                                backgroundColor: '#4285f4',
                                padding: '4px 7px',
                                borderRadius: 12
                            }}>
                                <PersonIcon color="white" width={18} height={18} />
                            </div>
                            <p className={TextStyles.BoldLarge} style={{ color: 'white' }}>Our Approach</p>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: 12 }}>
                            <div style={{
                                padding: '4px 8px',
                                borderRadius: 12,
                                border: '1px solid rgba(255,255,255,0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8
                            }}>
                                <PersonIcon color="white" width={18} height={18} />
                                <p className={TextStyles.BodyWhite}>You</p>
                            </div>
                            <ArrowRightIcon color="white" />
                            <div style={{
                                padding: '4px 8px',
                                borderRadius: 12,
                                border: '1px solid rgba(255,255,255,0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8
                            }}>
                                <PersonIcon color="#4285f4" width={18} height={18} />
                                <p className={TextStyles.BoldWhite}>Preferred Mentor</p>
                            </div>
                        </div>

                        <p className={TextStyles.Body} style={{ color: '#888', marginTop: 12, fontSize: '10.5pt' }}>
                            Connect directly with the perfect mentor for your needs, with no corporate overhead or middlemen.
                        </p>
                    </div>

                    {/* Their Approach Panel */}
                    <div style={{
                        width: isMobile ? '100%' : '48%',
                        background: 'linear-gradient(145deg, rgba(255,255,255,0.05), rgba(255,255,255,0.02))',
                        borderRadius: 16,
                        padding: 12,
                        border: '1px solid rgba(255,255,255,0.1)'
                    }}>
                        <div style={{
                            marginBottom: 16,
                            display: 'flex',
                            alignItems: 'center',
                            gap: 12
                        }}>
                            <div style={{
                                backgroundColor: '#666',
                                padding: '4px 7px',
                                borderRadius: 12
                            }}>
                                <CorporateIcon color="white" width={18} height={18} />
                            </div>
                            <p className={TextStyles.BoldLarge} style={{ color: 'white' }}>Traditional Approach</p>
                        </div>

                        <div style={{ display: 'flex', alignItems: 'center', gap: 10, }}>
                            <div style={{
                                padding: '4px 8px',
                                borderRadius: 12,
                                border: '1px solid rgba(255,255,255,0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8
                            }}>
                                <PersonIcon color="white" width={18} height={18} />
                                <p className={TextStyles.BodyWhite}>You</p>
                            </div>
                            <ArrowRightIcon color="white" />
                            <div style={{
                                padding: '4px 8px',
                                borderRadius: 12,
                                border: '1px solid rgba(255,255,255,0.1)',
                                background: 'rgba(255,255,255,0.05)',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8
                            }}>
                                <CorporateIcon color="#666" width={18} height={18} />
                                <p className={TextStyles.BodyWhite}>Company</p>
                            </div>
                            <ArrowRightIcon color="white" />
                            <div style={{
                                padding: '4px 8px',
                                borderRadius: 12,
                                border: '1px solid rgba(255,255,255,0.1)',
                                display: 'flex',
                                alignItems: 'center',
                                gap: 8
                            }}>
                                <PersonIcon color="#666" width={18} height={18} />
                                <p className={TextStyles.BodyWhite}>{isMobile ? '' : 'Random'} Mentor</p>
                            </div>
                        </div>

                        <p className={TextStyles.Body} style={{ color: '#888', marginTop: 12, fontSize: '10.5pt' }}>
                            Traditional companies take up to 80% commission, leading to higher costs and less qualified mentors.
                        </p>
                    </div>
                </div>
            </Container>
        </div>
    );
};

export default ApproachComparison;
import TextStyles from '../TextStyles.module.css';
import ButtonStyles from '../styles/buttons.module.css'
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import Footer from '../Footer/Footer';
import Config from '../../config.json';
import { useLocation, useNavigate } from 'react-router-dom';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const SurveyPP = () => {
    let navigate = useNavigate();

    return (
        <div style={{
            padding: '32px 8px',
            background: 'linear-gradient(135deg, rgba(245, 245, 255, 1) 0%, rgba(255, 228, 240, 1) 25%, rgba(255, 255, 225, 1) 50%, rgba(225, 240, 255, 1) 75%, rgba(240, 255, 245, 1) 100%)',
            fontFamily: 'Source Sans Pro',
        }}>
            <Container>

                <div className="text-center">
                    <p style={{ textTransform: 'uppercase', marginBottom: 0, fontWeight: '700' }}>The New Debate</p>
                    <h1 className={TextStyles.Title}>AI vs. Scope Expansion</h1>
                    <p className={TextStyles.Body} style={{ color: 'black' }}>
                        Understanding a Generation of Future Physicians
                    </p>
                </div>

                <div style={{ backgroundColor: 'white', border: '1px solid rgb(200,200,210)', borderRadius: 5, padding: 16, marginBottom: 8 }}>
                    <button className={ButtonStyles.StandardDark} onClick={() => { navigate(-1) }}>Back</button>

                    <h1>Privacy Policy for "AI vs. Scope Expansion" Survey</h1>
                    <p><strong>Effective Date:</strong> October 17, 2024</p>

                    <p>
                        <strong>Bright Doctor Inc.</strong> (referred to as "we", "us", or "our") respects the privacy of medical students
                        participating in the <strong>"AI vs. Scope Expansion: Understanding a Generation of Future Physicians"</strong> survey.
                        This privacy policy outlines how we collect, use, and protect your information.
                    </p>

                    <h2>1. Information We Collect</h2>
                    <p>As part of the survey process, we collect the following information:</p>
                    <ul>
                        <li>
                            <strong>Personal Identification Information:</strong> Your name, medical school, school email, and year of study.
                        </li>
                        <li>
                            <strong>Survey Responses:</strong> Your answers to the survey questions, which cover your familiarity with AI and
                            Advanced Practice Providers (APPs), your opinions on their impacts, and related information.
                        </li>
                    </ul>

                    <h2>2. How We Use Your Information</h2>
                    <ul>
                        <li>
                            <strong>Anonymization of Responses:</strong> While your individual responses will be anonymized when published or
                            shared in research publications, your personal information (e.g., name, medical school, and email) will be retained for
                            verification purposes.
                        </li>
                        <li>
                            <strong>Ownership of Responses:</strong> By participating in this survey, you agree that your responses become the
                            property of Bright Doctor Inc. We reserve the right to use your responses for:
                            <ul>
                                <li>
                                    <strong>Research and Publications:</strong> Your anonymized data may be used in research studies, journal articles, or reports.
                                </li>
                                <li>
                                    <strong>Commercial Purposes:</strong> We may analyze the data for business insights, including identifying trends in medical
                                    education and workforce planning, and use it to develop products or services.
                                </li>
                                <li>
                                    <strong>Internal Improvements:</strong> Data may also be used to improve our surveys, products, or educational resources for
                                    future medical students.
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <h2>3. Data Protection</h2>
                    <p>
                        We are committed to maintaining the security of your personal data. Your identifying information (name, email, school) will
                        only be used for verification and will not be shared with third parties without your consent, except where required by law.
                    </p>
                    <p>
                        Survey responses, once anonymized, will not be traceable back to you.
                    </p>

                    <h2>4. Data Sharing</h2>
                    <p>We do not share personal identification information with third parties without your consent, except:</p>
                    <ul>
                        <li>When required by law.</li>
                        <li>If necessary for technical reasons, such as data storage or processing by a trusted third-party service provider
                            (under confidentiality agreements).</li>
                    </ul>
                    <p>
                        Anonymized data, however, may be shared with third parties for research, commercial partnerships, or educational purposes.
                    </p>

                    <h2>5. Your Consent</h2>
                    <p>
                        By participating in this survey, you agree to the terms of this privacy policy. You understand that your survey responses
                        become the property of Bright Doctor Inc. and may be used in the ways described above.
                    </p>

                    <h2>6. Contact Us</h2>
                    <p>
                        If you have any questions or concerns about this privacy policy or the survey, please contact us at
                        <a href="mailto:info@brightstudent.org"> info@brightstudent.org</a>.
                    </p>

                    <h2>7. Changes to this Policy</h2>
                    <p>
                        We may update this privacy policy from time to time. When we do, we will update the effective date at the top of this policy.
                        Any changes will take effect immediately upon posting the updated policy.
                    </p>

                </div>
            </Container>

            <Footer />
        </div>
    );
};

export default SurveyPP;

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import axios from 'axios';
import { connect } from 'react-redux';
import TextStyles from '../../styles/text.module.css';
import ButtonStyles from '../../styles/buttons.module.css';
import Config from '../../../config.json'
import Styles from './MPHome.module.css';
import MPPanel from '../MPPanel/MPPanel';
import SiteStyles from '../../Site.module.css';
import { ArrowLeftIcon, ArrowRightIcon } from '../../Icons';
import SlimFilterBar from './SlimFilterBar';


const MPMarketplace = ({ dontNavigate = false, scrollToTop }) => {
    const [loadingConsultants, setLoadingConsultants] = useState(false);
    const [consultants, setConsultants] = useState([]);
    const [voucherMinOrderValue, setVoucherMinOrderValue] = useState(null);
    const [voucherValue, setVoucherValue] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [initialLoad, setInitialLoad] = useState(true);

    const fetchConsultants = (page = 1, scroll = false) => {
        setLoadingConsultants(true);
        const queryParams = new URLSearchParams(window.location.search);

        axios.get(`${Config.API_BASE_URL}/public/consultants`, {
            params: {
                page,
                limit: 12,
                search: queryParams.get('search') || '',
                tag: queryParams.get('tag') || '',
                subtag: queryParams.get('subtag') || ''
            }
        })
            .then(res => {
                setConsultants(res.data.data);
                setLoadingConsultants(false);
                if (res.data.voucherValue) {
                    setVoucherMinOrderValue(res.data.minOrderValue);
                    setVoucherValue(res.data.voucherValue);
                }
                setTotalPages(Math.ceil(res.data.total / 12));
                if (scroll) { scrollToTop(); }
            })
            .catch(err => { console.log("Error fetching consultants") });
    };

    // Add this useEffect to listen for URL parameter changes
    useEffect(() => {
        const handleUrlChange = () => {
            fetchConsultants(1, true);
        };

        window.addEventListener('popstate', handleUrlChange);
        window.addEventListener('urlChange', handleUrlChange);  // Add this line

        return () => {
            window.removeEventListener('popstate', handleUrlChange);
            window.removeEventListener('urlChange', handleUrlChange);  // And this line
        };
    }, []);
    useEffect(() => {
        fetchConsultants(currentPage, false);
        checkLoggedIn();
    }, []);

    useEffect(() => {
        if (!initialLoad) { fetchConsultants(currentPage, true); }
        else { setInitialLoad(false); }
    }, [currentPage]);

    const checkLoggedIn = () => {
        axios.get(`${Config.API_BASE_URL}/auth/check-headers`).then(res => {
            if (res.data.hasAuthHeaders) { setIsLoggedIn(true); }
        }).catch(err => { console.log(err); });
    };

    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) { setCurrentPage(newPage); }
    };

    const PaginationButton = ({ page, isActive, onClick }) => (
        <button
            onClick={onClick}
            style={{
                width: '40px',
                height: '40px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: isActive ? 'black' : '#ffffff',
                color: isActive ? '#ffffff' : '#404040',
                border: 'none',
                borderRadius: '8px',
                boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                transition: 'all 0.2s ease',
                cursor: 'pointer',
                fontSize: '14px',
                fontWeight: isActive ? '600' : '500',
            }}
            onMouseEnter={e => {
                if (!isActive) {
                    e.target.style.backgroundColor = '#f5f5f5';
                    e.target.style.transform = 'translateY(-1px)';
                }
            }}
            onMouseLeave={e => {
                if (!isActive) {
                    e.target.style.backgroundColor = '#ffffff';
                    e.target.style.transform = 'translateY(0)';
                }
            }}
        >
            {page}
        </button>
    );

    const renderPaginationButtons = () => {
        const maxVisibleButtons = 5;
        let startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2));
        let endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);

        if (endPage - startPage + 1 < maxVisibleButtons) {
            startPage = Math.max(1, endPage - maxVisibleButtons + 1);
        }

        return (
            <div style={{
                display: 'flex',
                alignItems: 'center',
                gap: '8px',
                padding: '8px',
            }}>
                <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    disabled={currentPage === 1}
                    style={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#ffffff',
                        border: 'none',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        cursor: currentPage === 1 ? 'default' : 'pointer',
                        opacity: currentPage === 1 ? '0.5' : '1',
                        transition: 'all 0.2s ease',
                    }}
                    onMouseEnter={e => {
                        if (currentPage !== 1) {
                            e.target.style.backgroundColor = '#f5f5f5';
                            e.target.style.transform = 'translateY(-1px)';
                        }
                    }}
                    onMouseLeave={e => {
                        if (currentPage !== 1) {
                            e.target.style.backgroundColor = '#ffffff';
                            e.target.style.transform = 'translateY(0)';
                        }
                    }}
                >
                    <ArrowLeftIcon size={20} color="#404040" />
                </button>

                {startPage > 1 && (
                    <>
                        <PaginationButton
                            page={1}
                            isActive={currentPage === 1}
                            onClick={() => handlePageChange(1)}
                        />
                        {startPage > 2 && (
                            <span style={{
                                color: '#404040',
                                fontSize: '14px',
                                fontWeight: '500',
                                padding: '0 4px'
                            }}>...</span>
                        )}
                    </>
                )}

                {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map(page => (
                    <PaginationButton
                        key={page}
                        page={page}
                        isActive={currentPage === page}
                        onClick={() => handlePageChange(page)}
                    />
                ))}

                {endPage < totalPages && (
                    <>
                        {endPage < totalPages - 1 && (
                            <span style={{
                                color: '#404040',
                                fontSize: '14px',
                                fontWeight: '500',
                                padding: '0 4px'
                            }}>...</span>
                        )}
                        <PaginationButton
                            page={totalPages}
                            isActive={currentPage === totalPages}
                            onClick={() => handlePageChange(totalPages)}
                        />
                    </>
                )}

                <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    disabled={currentPage === totalPages}
                    style={{
                        width: '40px',
                        height: '40px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#ffffff',
                        border: 'none',
                        borderRadius: '8px',
                        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
                        cursor: currentPage === totalPages ? 'default' : 'pointer',
                        opacity: currentPage === totalPages ? '0.5' : '1',
                        transition: 'all 0.2s ease',
                    }}
                    onMouseEnter={e => {
                        if (currentPage !== totalPages) {
                            e.target.style.backgroundColor = '#f5f5f5';
                            e.target.style.transform = 'translateY(-1px)';
                        }
                    }}
                    onMouseLeave={e => {
                        if (currentPage !== totalPages) {
                            e.target.style.backgroundColor = '#ffffff';
                            e.target.style.transform = 'translateY(0)';
                        }
                    }}
                >
                    <ArrowRightIcon size={20} color="#404040" />
                </button>
            </div>
        );
    };



    return (
        <div id="marketplace-grid" style={{ backgroundColor: 'rgb(235,235,245)', padding: '24px 16px', zIndex: 100, width: '100%', }}>
            <p className={TextStyles.Body} style={{ color: 'black', fontWeight: '600', textAlign: 'center', fontSize: '14pt', marginBottom: 16 }}>
                OUR CONSULTANTS
            </p>

            <SlimFilterBar
                onSearch={(term) => fetchConsultants(1, true)}
                onFilterChange={(filters) => fetchConsultants(1, true)}
            />

            {
                loadingConsultants ?
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', padding: '64px' }}>
                        <div className={SiteStyles.Spinner} />
                    </div>
                    :
                    <Container>

                        <div className={Styles.MarketplaceGrid} style={{ marginTop: 32 }}>
                            {consultants.map(x => (
                                <MPPanel
                                    key={x._id}
                                    isLoggedIn={isLoggedIn}
                                    consultant={x}
                                    dontNavigate={dontNavigate}
                                    voucherMinOrderValue={voucherMinOrderValue}
                                    voucherValue={voucherValue}
                                />
                            ))}
                        </div>


                        {totalPages > 1 && (
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 16 }}>
                                {renderPaginationButtons()}
                            </div>
                        )}


                        <div style={{ textAlign: 'center', margin: '32px 0px 16px 0px' }}>
                            <p className={TextStyles.Bold} style={{ color: '#555', fontWeight: 600, marginBottom: 16 }}>Interested in Becoming a Consultant?</p>
                            <button
                                className={ButtonStyles.StandardLight}
                                style={{ backgroundColor: 'white' }}
                                onClick={() => window.open('https://admissionpeer.com/article/become-consultant', '_blank')}
                            >
                                Learn More
                            </button>
                        </div>
                    </Container>
            }
        </div>

    );
};

const mapStateToProps = (state) => {
    return { isAuthenticated: state.isAuthenticated };
};

export default connect(mapStateToProps)(MPMarketplace);